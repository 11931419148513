import Cookies from "js-cookie";
import { contactCookieDomain } from "../Config/Constants";

function getCookieDomain() {
  if (window.location.hostname === "localhost") {
    return "localhost";
  }

  return contactCookieDomain;
}

export const getCookie = (name: string) => Cookies.get(name);

export const setCookie = (name: string, value: string, expiryDate: Date) => {
  const domain = getCookieDomain();

  Cookies.set(name, value, {
    path: "/",
    domain,
    expires: expiryDate,
    secure: true,
  });
};

export const removeCookie = (name: string) => {
  const domain = getCookieDomain();

  Cookies.remove(name, {
    path: "/",
    domain,
  });
};
