import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./Address.scss";
import Input from "../Input";
import Button from "../../Components/Button";
import noop from "nop";

/**
 * Checks if given address is valid.
 * @param streetNumber
 * @param streetName
 * @param city
 * @param postcode
 * @returns {*}
 */
function isValidAddress({ streetNumber, streetName, city, postcode }) {
  return (
    streetNumber && streetName && city && postcode && postcode.match(/^\d{4}$/)
  );
}

/**
 * Takes address object and converts it into a string.
 * @param unit
 * @param streetNumber
 * @param streetName
 * @param suburb
 * @param city
 * @param postcode
 * @returns {string}
 */
function stringifyAddressForm({
  unit,
  streetNumber,
  streetName,
  suburb,
  city,
  postcode,
}) {
  return [unit, streetNumber + " " + streetName, suburb, city + " " + postcode]
    .filter(Boolean)
    .join(", ");
}

const AddressForm = ({
  hasEnterButton = false,
  handleError: defaultHandleError = AddressForm.defaultProps.handleError,
  handleChange,
  validateItself = false,
  suburbRequired = false,
  showButton = true,
}) => {
  const [unit, setUnit] = useState("");
  const [unitError, setUnitError] = useState(false);
  const [streetNumber, setStreetNumber] = useState("");
  const [streetNumberError, setStreetNumberError] = useState(false);
  const [streetName, setStreetName] = useState("");
  const [streetNameError, setStreetNameError] = useState(false);
  const [suburb, setSuburb] = useState("");
  const [suburbError, setSuburbError] = useState(false);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [postcodeError, setPostcodeError] = useState(false);
  const [formError, setFormError] = useState(false);

  const address = useMemo(() => {
    return !formError &&
      isValidAddress({ unit, streetNumber, streetName, suburb, city, postcode })
      ? stringifyAddressForm({
          unit,
          streetNumber,
          streetName,
          suburb,
          city,
          postcode,
        })
      : null;
  }, [formError, unit, streetNumber, streetName, suburb, city, postcode]);

  useEffect(() => {
    if (!hasEnterButton) {
      handleChange(address);
    }
  }, [address, handleChange, hasEnterButton]);

  useEffect(() => {
    if (defaultHandleError) {
      defaultHandleError(formError);
    }
  }, [formError, defaultHandleError]);

  const handleError = useCallback(
    (isError, setError) => {
      setError(isError);
      setFormError(
        unitError ||
          streetNumberError ||
          streetNameError ||
          suburbError ||
          cityError ||
          postcodeError
      );
    },
    [
      cityError,
      postcodeError,
      streetNameError,
      streetNumberError,
      suburbError,
      unitError,
    ]
  );

  const validatePostcode = useCallback(
    (postcode) => {
      setPostcode(postcode);
      setPostcodeError(!(postcode.match(/[0-9]/) && postcode.length < 5));
    },
    [setPostcode]
  );

  const validateAddress = useCallback(() => {
    if (address) {
      handleChange(address, {
        unit,
        streetNumber,
        streetName,
        suburb,
        city,
        postcode,
      });
    } else {
      if (!streetNumber) {
        setStreetNumberError(true);
      } else if (!streetName) {
        setStreetNameError(true);
      } else if (!suburb) {
        setSuburbError(true);
      } else if (!city) {
        setCityError(true);
      } else if (!postcode) {
        setPostcodeError(true);
      }
    }
  }, [
    address,
    city,
    handleChange,
    postcode,
    streetName,
    streetNumber,
    suburb,
    unit,
  ]);

  return (
    <div className="flexdiv_container">
      <div className="flexdiv">
        <div className="flexdiv--half">
          <Input
            name="unit"
            handleChange={setUnit}
            value={unit}
            labelText="Unit number"
            placeholder="Enter a Unit number"
            handleError={(isError) => handleError(isError, setUnitError)}
            hasError={unitError}
            errorMessage="Unit number is required"
          />
        </div>
        <div className="flexdiv--half">
          <Input
            name="streetNumber"
            handleChange={setStreetNumber}
            value={streetNumber}
            labelText="Street number"
            placeholder="Enter a Street number"
            required
            handleError={(isError) =>
              handleError(isError, setStreetNumberError)
            }
            hasError={streetNumberError}
            errorMessage="Street number is required"
            validationType="streetNumber"
            invalidMessage="Invalid street number"
            validateItself={validateItself}
          />
        </div>
      </div>
      <div className="flexdiv">
        <div className="flexdiv--half">
          <Input
            name="streetName"
            handleChange={setStreetName}
            value={streetName}
            labelText="Street name"
            placeholder="Enter a Street name"
            required
            handleError={(isError) => handleError(isError, setStreetNameError)}
            hasError={streetNameError}
            errorMessage="Street name is required"
            validateItself={validateItself}
          />
        </div>
        <div className="flexdiv--half">
          <Input
            name="suburb"
            handleChange={setSuburb}
            value={suburb}
            labelText="Suburb"
            placeholder="Enter a Suburb"
            handleError={(isError) => handleError(isError, setSuburbError)}
            hasError={suburbError}
            errorMessage="Suburb is required"
            required={suburbRequired}
          />
        </div>
      </div>
      <div className="flexdiv flexdiv_column">
        <div className="flexdiv--two_third">
          <Input
            name="city"
            handleChange={setCity}
            value={city}
            labelText="City"
            placeholder="Enter a City"
            required
            handleError={(isError) => handleError(isError, setCityError)}
            hasError={cityError}
            errorMessage="City is required"
            validateItself={validateItself}
          />
        </div>
        <div className="flexdiv--third">
          <Input
            name="postcode"
            handleChange={(postcode) => validatePostcode(postcode)}
            value={postcode}
            labelText="Postcode"
            placeholder="Enter a Postcode"
            required
            handleError={(isError) => handleError(isError, setPostcodeError)}
            hasError={postcodeError}
            errorMessage="Postcode is required"
            validationType="postcode"
            invalidMessage="Invalid postcode number"
            validateItself={validateItself}
          />
        </div>
      </div>
      {showButton && (
        <div className="flexdiv_buttonContainer">
          <Button
            className="flexdiv_buttonContainer_button"
            text="Enter address"
            type="button"
            handleClick={validateAddress}
            primaryOnLight
          />
        </div>
      )}
    </div>
  );
};

AddressForm.defaultProps = {
  handleError: noop,
};

export default AddressForm;
