import React, { useState, useEffect, useCallback } from "react";
import { useSpring, animated, config } from "react-spring";
import classNames from "classnames";
import _debounce from "lodash/debounce";

import DatePicker from "react-datepicker";
import Icon from "../Icon";
import { Calendar } from "../../Assets/Icons";
import DateUtil from "../../Utils/DateUtil";
import {
  validateDate,
  validateFirstDateBeforeSecond,
} from "../../Utils/ValidationUtil";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";

const DataPicker = ({
  validateItself = false,
  hasError = false,
  required = false,
  value,
  handleError = undefined,
  iconLast = false,
  isSmall = false,
  minDate = undefined,
  maxDate = undefined,
  handleChange,
  minErrorMessage = "",
  maxErrorMessage = "",
  validDates = [],
  name,
  disabled = false,
  labelText,
  errorMessage = undefined,
  numberValidDays = 0,
  placeholder = undefined,
  onlyWeekdays = false,
  customFilter = undefined,
  numberYearOptions = 0,
  inputProps = undefined,
}) => {
  const MobileWidth = 640;
  const [placeholderState, setPlaceholderState] = useState("");
  const [isActive, setActive] = useState(false);
  const [withPortal, setWithPortal] = useState(false);

  //trigger auto validation
  useEffect(() => {
    if (validateItself && !hasError && required && !value && handleError) {
      handleError(true);
    }
  }, [validateItself, value, handleError, required, hasError]);

  useEffect(() => {
    setActive((isActive) => (value ? true : isActive));
  }, [value]);

  const getResizeData = () => {
    setWithPortal(window.innerWidth < MobileWidth);
  };

  const debounceGetResizeData = _debounce(getResizeData, 150);

  useEffect(() => {
    window.addEventListener("resize", debounceGetResizeData);
    return () => {
      window.removeEventListener("resize", debounceGetResizeData);
    };
  });

  useEffect(() => {
    getResizeData();
  }, []);

  const handleFilter = useCallback(
    (date) => {
      const weekdaysValid = onlyWeekdays ? DateUtil.isWeekday(date) : true;
      const customFilterValid = customFilter ? customFilter(date) : true;
      return weekdaysValid && customFilterValid;
    },
    [customFilter, onlyWeekdays]
  );

  const [animateInput, setInput] = useSpring(() => ({
    opacity: 0,
    config: config.stiff,
    width: isSmall ? "150px" : "100%",
  }));

  setInput({
    opacity: isActive ? 1 : 0,
  });

  const dateOnChangeHandler = (value) => {
    if (handleError) {
      validateDate(minDate, maxDate, value, handleError);
    }
    handleChange(value);
  };

  const replaceInputHandler = (event) => {
    const cursorPosition = event.target.selectionStart;
    const initiallength = event.target.value.length;
    event.target.value = event.target.value
      .replace(/[^\d-]/g, "")
      // eslint-disable-next-line no-useless-escape
      .replace(/[\/]?/g, "")
      .replace(/(^\d{2})/g, "$1/")
      .replace(/(^\d{2}\/\d{2})/g, "$1/")
      // eslint-disable-next-line no-useless-escape
      .replace(/[\/]$/g, "");
    const finallength = event.target.value.length;
    const newCursorPosition = cursorPosition + finallength - initiallength;
    event.target.setSelectionRange(newCursorPosition, newCursorPosition);
  };

  const dateOnFocusHandler = (event) => {
    event.target.readOnly = window.innerWidth < MobileWidth;
    setPlaceholderState(placeholder);
    setActive(true);
  };

  const dateOnBlurHandler = () => {
    setPlaceholderState("");
    setActive(value != null);
  };

  const getErrorMessage = () => {
    return validateFirstDateBeforeSecond(value, minDate)
      ? minErrorMessage
      : maxErrorMessage;
  };

  const customInput = (
    <animated.input
      id={name}
      name={name}
      type="text"
      htmlFor={name}
      style={animateInput}
      maxLength={10}
    />
  );

  const dates = validDates ? validDates : [];

  if (numberValidDays) {
    for (let days = 0; days < numberValidDays; days++) {
      dates.push(DateUtil.addDays(new Date(), days));
    }
  }

  const icon = (
    <span className="dateContainer_calendar">
      <Icon icon={<Calendar />} className="dateContainer_calendar_icon" />
    </span>
  );

  const classes = classNames({
    dateContainer: true,
    "dateContainer--spacearound": iconLast,
  });

  return (
    <div className="dateContainer_main">
      {labelText && (
        <animated.label
          id={name}
          name={name}
          htmlFor={name}
          className={
            required ? "dateContainer_label required" : "dateContainer_label"
          }
        >
          {labelText}
        </animated.label>
      )}
      <div
        className={classes}
        aria-disabled={disabled}
        ref={inputProps && inputProps.ref}
      >
        {/* eslint-disable-next-line*/}

        {/* eslint-disable jsx-a11y/label-has-for */}

        {iconLast ? null : icon}
        <DatePicker
          className="dateContainer_input"
          dateFormat="dd/MM/yyyy"
          selected={value}
          onChange={dateOnChangeHandler}
          placeholderText={placeholderState}
          required={required}
          disabled={disabled}
          onFocus={dateOnFocusHandler}
          onBlur={dateOnBlurHandler}
          customInput={customInput}
          filterDate={handleFilter}
          maxDate={maxDate}
          minDate={minDate}
          withPortal={withPortal}
          onChangeRaw={replaceInputHandler}
          disabledKeyboardNavigation
          showYearDropdown
          dateFormatCalendar="MMMM"
          scrollableYearDropdown
          yearDropdownItemNumber={numberYearOptions ? numberYearOptions : 3}
        />
        {!hasError && iconLast ? icon : null}
      </div>
      {hasError && (
        <label id={name} htmlFor={name} className="dateContainer_label--error">
          {value && (minErrorMessage || maxErrorMessage)
            ? getErrorMessage()
            : errorMessage}
        </label>
      )}
    </div>
  );
};

export default DataPicker;
