import React, { useEffect, useState } from "react";
import { Dropdown } from "react-components";
import Input from "../Input";
import Modal from "../Modal";
import "./ServiceCard.scss";
import { ServiceModalConstants } from "../../Config/Constants";
import {
  triggerConfirmPipedGasICPModalShownUp,
  triggerConfirmPipedGasICPModalManuallyShownUp,
  triggerConfirmButton,
  triggerCancelButton,
} from "../../../Utils/analytics";

const modalButtonCustomStyle = { height: "auto" };

export interface ServiceCardPipedGasModalProps {
  modalType?: string;
  handlePrimaryClick: (event: string) => void;
  handleIcpChange: (event: string) => void;
  isActive: boolean;
  icpHasApiError: boolean;
  addressList?: Array<Record<string, string>>;
  setShowNoIcpModal?: React.MouseEventHandler;
  addressIcp?: string;
  setAddressIcp?: (arg: string) => void;
}

const ServiceCardPipedGasModal = ({
  modalType,
  handlePrimaryClick,
  handleIcpChange,
  isActive,
  addressList,
  icpHasApiError,
  setShowNoIcpModal,
  addressIcp = undefined,
  setAddressIcp,
}: ServiceCardPipedGasModalProps) => {
  const [IcpHasError, setIcpHasError] = useState(false);

  // ICP Modal
  const [icp, setIcp] = useState("");
  const [validateItself, setValidateItself] = useState(false);

  useEffect(() => {
    if (isActive) {
      switch (modalType) {
        case "pipedGasIcp":
          triggerConfirmPipedGasICPModalManuallyShownUp();
          return;
        case "multiplePipedGasIcp":
          triggerConfirmPipedGasICPModalShownUp();
          return;
        default:
          return;
      }
    }
  }, [isActive]);

  const buildIcpModalForPipedGas = () => {
    return (
      <Modal
        showClose
        clearSessionStyle
        isActive={isActive}
        buttonTextPrimary={ServiceModalConstants.pipedGasButtonTextPrimary}
        handlePrimaryClick={(e) => {
          handlePrimaryClick(e);
          setIcp("");
          triggerCancelButton();
        }}
        buttonTextSecondary={ServiceModalConstants.pipedGasButtonTextSecondary}
        handleSecondaryClick={() => {
          handleIcpChange(icp);
          triggerConfirmButton();
        }}
        buttonDisabled={
          IcpHasError || icp.length !== ServiceModalConstants.icpMaxLength
        }
        handleValidationClick={() => setValidateItself(false)}
        content={buildPipedGasIcpModalContent()}
        title={ServiceModalConstants.pipedGasIcpConfirmTitle}
        buttonCustomStyle={modalButtonCustomStyle}
      />
    );
  };

  const buildMultipleIcpModalForPipedGas = () => {
    return (
      <Modal
        showClose
        clearSessionStyle
        isActive={isActive}
        buttonTextPrimary={
          ServiceModalConstants.pipedGasMultipleIcpButtonTextPrimary
        }
        handlePrimaryClick={(e) => {
          handlePrimaryClick(e);
          // setAddressIcp("");
          triggerCancelButton();
        }}
        buttonTextSecondary={ServiceModalConstants.pipedGasButtonTextSecondary}
        handleSecondaryClick={() => {
          const icpValue = addressIcp
            ? addressIcp.split("ICP:")[1].replace(/["]+/g, "")
            : "";
          if (icpValue) {
            handleIcpChange(icpValue);
            triggerConfirmButton();
          }
        }}
        buttonDisabled={addressIcp || !icpHasApiError ? false : true}
        handleValidationClick={() => setValidateItself(false)}
        content={buildPipedGasMultipleIcpModalContent()}
        title={ServiceModalConstants.pipedGasMultipleIcpConfirmTitle}
      />
    );
  };

  const buildPipedGasMultipleIcpModalContent = () => {
    return (
      <>
        <div className="serviceCard_dropdown">
          <Dropdown
            placeholder={ServiceModalConstants.MultipleIcpPlaceHolderMessage}
            items={
              addressList && addressList.length > 1
                ? addressList.map((address) => {
                    return address.name
                      ? address.name
                      : address.icp
                      ? address.address + "- ICP:" + address.icp
                      : address.address;
                  })
                : []
            }
            selectedItem={addressIcp}
            handleSelectedItem={setAddressIcp}
            labelText={ServiceModalConstants.pipedGasMultipleIcpConfirmSubTitle}
          />
        </div>
        <div
          className="serviceCardField_rightColumn_manual_link address button--link"
          onClick={setShowNoIcpModal}
        >
          {ServiceModalConstants.pipedGasNoIcpLink}
        </div>
        {icpHasApiError && (
          <p className="serviceCard_errorContainer">
            {ServiceModalConstants.IcpNumberNotFoundMsg}
          </p>
        )}
      </>
    );
  };

  const buildPipedGasIcpModalContent = () => {
    return (
      <>
        <div className="serviceCard_subtitle">
          <span>{ServiceModalConstants.pipedGasIcpConfirmSubTitle} </span>
        </div>
        <Input
          labelText={ServiceModalConstants.icpNumberLabel}
          handleChange={setIcp}
          value={icp}
          maxLength={ServiceModalConstants.icpMaxLength}
          validationType="invalidIcpLength"
          required
          showSuccessIcon
          errorMessage={ServiceModalConstants.IcpNumberRequiredMsg}
          invalidMessage={ServiceModalConstants.IcpNumberInvalidMsg}
          hasError={IcpHasError}
          handleError={setIcpHasError}
          validateItself={validateItself}
        />
        {icpHasApiError && (
          <p className="serviceCard_errorContainer">
            {ServiceModalConstants.IcpNumberNotFoundMsg}
          </p>
        )}
        <div className="modal_info">
          {ServiceModalConstants.pipedGasIcpInfoText}
        </div>
      </>
    );
  };

  switch (modalType) {
    case "pipedGasIcp":
      return buildIcpModalForPipedGas();
    case "multiplePipedGasIcp":
      return buildMultipleIcpModalForPipedGas();
    default:
      return null;
  }
};
export default ServiceCardPipedGasModal;
