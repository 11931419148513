import React, { useState, useEffect } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";
import HTML from "../HTML";
import Icon from "../Icon";
import Button from "../Button";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  LongArrowUp,
  LongArrowDown,
  WhiteCheck,
  Close,
  ArrowRight,
} from "../../Assets/Icons";
import NavigationDots from "../NavigationDots";
import "./BroadbandCarousel.scss";

const generateSpeedDescription = (speed, postfix) => {
  if (speed.trim().endsWith("Mbps")) {
    return `${speed.trim().slice(0, -4)} ${postfix}`;
  } else {
    return `${speed} ${postfix}`;
  }
};

const BroadbandCarousel = (props) => {
  const [itemChecked, setItemChecked] = useState(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [dragScroll, setDragScroll] = useState(0);
  const [controlsScroll, setControllsScroll] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [isTCModalActive, setIsTCModalActive] = useState(false);
  const modalRoot = document.getElementById("modalRoot");
  const step = 270;

  const [modal, setModal] = useState({
    content: "",
    tCButtonText: "",
    choseButtonText: "",
  });

  useEffect(() => {
    setItemChecked(props.planIndex);
  }, [props.planIndex]);

  const onItemSelect = (index) => {
    setIsModalActive(false);
    setItemChecked(itemChecked !== index ? index : null);
    if (itemChecked !== index) {
      props.onPress(props.data[index], index);
    } else {
      props.onPress({}, null);
    }
  };

  const scrollToElement = (params, isIndex) => {
    setControllsScroll(true);
    let index = 0;
    if (scrollIndex >= 0) {
      if (isIndex) {
        index = params;
      } else {
        index =
          scrollIndex +
          (params === "right"
            ? scrollIndex < props.data.length - 1
              ? 1
              : 0
            : scrollIndex > 0
            ? -1
            : 0);
      }
    }
    const name = props.data[index]?.Id;
    const element = document.getElementById(name);
    setScrollIndex(index);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
      if (index === props.data.length - 1) {
        setScrollIndex(index);
      }
    }
  };

  const returnBBProviderUI = (isDiscount, item, index) => {
    const itemDescription =
      item.NetworkDesc && item.BYOModemAllowed
        ? item.NetworkDesc + ", " + props.constants.byoModemText
        : item.NetworkDesc
        ? item.NetworkDesc
        : item.BYOModemAllowed
        ? props.constants.byoModemText
        : "";
    return (
      <>
        {!!item.Label && (
          <div className="broadbandCarousel_block_label">{item.Label}</div>
        )}
        <div className="broadbandCarousel_block_header">{item.Name}</div>
        <div className="broadbandCarousel_block_priceContainer">
          <div className="broadbandCarousel_block_priceContainer_amount">
            {isDiscount ? item.BundlePrice : item.OriginalPrice}
          </div>
          {isDiscount && (
            <div className="broadbandCarousel_block_priceContainer_originPrice">
              <div className="broadbandCarousel_block_priceContainer_originPrice_crossed">
                {item.OriginalPrice}
              </div>
              <div className="broadbandCarousel_block_priceContainer_originPrice_crossLine" />
            </div>
          )}
        </div>
        <div className="broadbandCarousel_block_priceContainer_dataCap">
          {item.DataCapDesc}
        </div>
        <div className="broadbandCarousel_block_priceContainer_nwkDesc">
          {itemDescription}
        </div>
        <div className="broadbandCarousel_block_speed">
          {item.DownloadSpeed && (
            <div className="broadbandCarousel_block_speed_speedContainer">
              <Icon
                icon={<LongArrowDown />}
                className="broadbandCarousel_block_speed_speedContainer_icon"
              />
              <div className="broadbandCarousel_block_speed_speedContainer_text">
                {generateSpeedDescription(
                  item.DownloadSpeed,
                  props.constants.download
                )}
              </div>
            </div>
          )}
          {item.UploadSpeed && (
            <div className="broadbandCarousel_block_speed_speedContainer">
              <Icon
                icon={<LongArrowUp />}
                className="broadbandCarousel_block_speed_speedContainer_icon"
              />
              <div className="broadbandCarousel_block_speed_speedContainer_text">
                {generateSpeedDescription(
                  item.UploadSpeed,
                  props.constants.upload
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderButton = (item, index) => {
    return (
      <Button
        className={classNames(
          "broadbandCarousel_block_button",
          itemChecked === index
            ? "broadbandCarousel_block_button_buttonChecked"
            : ""
        )}
        handleClick={() => onItemSelect(index)}
        id={`choose-${item.Name}-button`}
        text={
          itemChecked === index ? (
            <Icon
              icon={<WhiteCheck />}
              size={28}
              className="broadbandCarousel_block_speed_speedContainer_icon"
            />
          ) : (
            <div className="broadbandCarousel_block_button_buttonText">
              {props.constants.button}
            </div>
          )
        }
      />
    );
  };

  const renderButtonLink = (isDiscount, item, index) => {
    let [kenticoData] = props.kenticoBBOffers?.filter((offerPlan) => {
      return item.Id === offerPlan.Id;
    });

    return kenticoData?.LearnMoreDescription ? (
      <Button
        id={`learn-${item.Name}-button`}
        text={props.constants.buttonLink}
        handleClick={() =>
          onModalShow(
            isDiscount,
            item,
            kenticoData?.LearnMoreDescription,
            index
          )
        }
        className="broadbandCarousel_block_button_link"
      />
    ) : null;
  };

  const onModalShow = (isDiscount, item, learnMoreDescription, index) => {
    setIsModalActive(true);
    setModal({
      header: item.Name,
      content: learnMoreDescription,
      card: returnBBProviderUI(isDiscount, item, index),
      buttonIndex: index,
      item: item,
    });
  };

  const onScrollStart = (event) => {
    setDragScroll(event);
  };

  const onScrollEnd = (event) => {
    if (!controlsScroll) {
      const scrollDifference = event - dragScroll;
      const scrollDitanse = Math.round(scrollDifference / step) * step;
      const numberOfSteps = Math.round(scrollDitanse / step);
      if (numberOfSteps >= 0) {
        setScrollIndex(scrollIndex + numberOfSteps);
      } else if (numberOfSteps < 0) {
        setScrollIndex(scrollIndex - Math.abs(numberOfSteps));
      }
      setControllsScroll(false);
    } else {
      setControllsScroll(false);
    }
  };

  const scrollRight = () => {
    scrollToElement("right", false);
  };

  const scrollLeft = () => {
    scrollToElement("left", false);
  };

  const renderListItem = (list) => (
    <ScrollContainer
      className="broadbandCarousel"
      onStartScroll={(event) => onScrollStart(event)}
      onEndScroll={(event) => onScrollEnd(event)}
    >
      {list.map((item, index) => {
        const isDiscount =
          item.BundlePrice && item.BundlePrice !== item.OriginalPrice;
        return (
          <div
            key={index}
            className="broadbandCarousel_block"
            id={item.Id}
            data-productid={item.Id}
          >
            {returnBBProviderUI(isDiscount, item, index)}
            {renderButton(item, index)}
            {renderButtonLink(isDiscount, item, index)}
          </div>
        );
      })}
    </ScrollContainer>
  );

  return (
    <div className="broadbandCarouselContainer">
      {isModalActive &&
        ReactDOM.createPortal(
          <div className="broadbandCarousel_modal">
            <div className="broadbandCarousel_modal_container">
              <div className="broadbandCarousel_modal_container_mini-card">
                <div
                  className="broadbandCarousel_modal_container_mini-card_close"
                  onClick={() => setIsModalActive(false)}
                >
                  <Icon
                    icon={<Close />}
                    className="broadbandCarousel_modal_container_mini-card_close_icon"
                  />
                </div>
                <div className="broadbandCarousel_modal_container_block--card">
                  {modal.card}
                </div>
              </div>
              <div className="broadbandCarousel_modal_container_block">
                <div className="broadbandCarousel_modal_container_block_header">
                  {modal.header}
                </div>
                <HTML html={modal.content} />
                <div className="broadbandCarousel_modal_container_block_buttons">
                  {renderButton(modal.item, modal.buttonIndex)}
                </div>
              </div>
            </div>
          </div>,
          modalRoot
        )}
      <div className="broadbandCarousel_listContainer">
        {props.data.length > 3 && (
          <div
            className="broadbandCarousel_arrow broadbandCarousel_arrow--left"
            onClick={scrollLeft}
          >
            <Icon icon={<ArrowRight />} size={28} />
          </div>
        )}
        {renderListItem(props.data)}
        {props.data.length > 3 && (
          <div
            className="broadbandCarousel_arrow broadbandCarousel_arrow--right"
            onClick={scrollRight}
          >
            <Icon icon={<ArrowRight />} size={28} />
          </div>
        )}
      </div>
      <NavigationDots
        elements={props.data}
        index={scrollIndex}
        onClick={scrollToElement}
      />
    </div>
  );
};

export default BroadbandCarousel;
