import React from "react";
import { compose, branch, renderNothing, withProps } from "recompose";
import classNames from "classnames";
import Icon from "../../Icon";
import { AppElectricity } from "../../../Assets/Icons";
import {
  USAGE_TYPE_LOW,
  USAGE_TYPE_STANDARD,
  SERVICE_ID_ELECTRICITY,
  SERVICE_ID_PIPED_GAS,
} from "../constants";

import Tooltip from "../../Tooltip";
import { triggerTooltip } from "../../../../Utils/analytics";

const usageTypeLabel = {
  [USAGE_TYPE_LOW]: "Low",
  [USAGE_TYPE_STANDARD]: "Standard",
};
const DetailedRate = ({
  rate,
  usageType,
  messages,
  areRatesToggleable,
  onToggleUsageType,
  currentRates,
  isNarrow,
  isSummary,
  isIcon,
  managedContent,
}) => {
  const usageTypeMessage =
    usageType === USAGE_TYPE_LOW
      ? messages.lowUserRates
      : messages.standardUserRates;

  const handleTooltipHover = () => {
    triggerTooltip({
      type: `${messages.electricityLabel} (${usageTypeMessage})`,
      info_text: managedContent?.RatesTTDescription.value?.substring(0, 100),
    });
  };

  return (
    <div
      className={classNames(
        "ratesContent_detailedRate",
        isNarrow ? "ratesContent_detailedRate--narrow" : "",
        isSummary ? "ratesContent_detailedRate--summary" : ""
      )}
    >
      <div className="ratesContent_detailedRate_heading">
        <div className="ratesContent_detailedRate_heading_title">
          {rate.type === SERVICE_ID_ELECTRICITY && !isSummary && (
            <div className="ratesContent_detailedRate_label">
              {isIcon && (
                <Icon
                  icon={<AppElectricity />}
                  className="ratesContent_detailedRate_label_icon"
                />
              )}
              <RateLabel
                label={messages.electricityLabel}
                userRatesLabel={usageTypeMessage}
              />
              <Tooltip
                className="ratesContent_detailedRate_tooltip"
                onMouseEnter={handleTooltipHover}
              >
                {managedContent?.RatesTTDescription.value}
              </Tooltip>
            </div>
          )}
          {rate.type === SERVICE_ID_PIPED_GAS && (
            <RateLabel
              label={messages.gasLabel}
              userRatesLabel={
                usageType === USAGE_TYPE_LOW
                  ? messages.lowUserRates
                  : messages.standardUserRates
              }
            />
          )}
        </div>
      </div>
      <div className="ratesContent_detailedRate_data">
        <div className="ratesContent_detailedRate_data_rates">
          {currentRates.map((r, i) => (
            <Rate
              key={"DR" + i}
              title={r.name}
              price={r.rate}
              value={r.type}
              description={r.description}
              messages={messages}
              isNarrow={isNarrow}
              isSummary={isSummary}
            />
          ))}
        </div>
        {areRatesToggleable && (
          <div>
            <span
              className="ratesContent_detailedRate_link"
              onClick={onToggleUsageType}
            >
              {getSwitchRatesLabel(usageType, messages.usageTypeSwitch)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const getSwitchRatesLabel = (usageType, message) => {
  const switchedUsageType =
    usageType === USAGE_TYPE_STANDARD ? USAGE_TYPE_LOW : USAGE_TYPE_STANDARD;
  const utLabel = usageTypeLabel[switchedUsageType];
  if (!message || !utLabel) return "";
  return message.replace("%usageType%", utLabel.toLowerCase());
};

const RateLabel = ({ label, userRatesLabel }) => (
  <>
    <strong style={{ marginRight: 5 }}>{label}</strong>
    {"(" + userRatesLabel + ")"}
  </>
);

const Rate = (props) => (
  <div
    className={classNames(
      "ratesContent_detailedRate_data_rates_rate",
      props.isNarrow ? "ratesContent_detailedRate_data_rates_narrow-rate" : "",
      props.isSummary ? "ratesContent_detailedRate_data_rates_rate_summary" : ""
    )}
  >
    <span
      className={classNames(
        props.isSummary
          ? "ratesContent_detailedRate_data_rates_rate_title_summary"
          : "ratesContent_detailedRate_data_rates_rate_title"
      )}
    >
      {props.title}
    </span>
    <span className="ratesContent_detailedRate_data_rates_rate_value">
      {props.description ? (
        props.description
      ) : (
        <>
          <strong>{props.price}</strong>/{props.value}
        </>
      )}
    </span>
  </div>
);

const renderRates = (usageType, rate, isGSTEnabled) => {
  switch (true) {
    case usageType === USAGE_TYPE_LOW && isGSTEnabled:
      return rate.lowRatesGSTInclusive;

    case usageType === USAGE_TYPE_STANDARD && isGSTEnabled:
      return rate.standardRatesGSTInclusive;

    case usageType === USAGE_TYPE_LOW && !isGSTEnabled:
      return rate.lowRatesGSTExclusive;

    case usageType === USAGE_TYPE_STANDARD && !isGSTEnabled:
      return rate.standardRatesGSTExclusive;

    default:
      break;
  }
};

export default compose(
  branch(
    // render only for electricity and piped gas and only if rates are set
    ({ rate, usageType }) =>
      !rate ||
      ![SERVICE_ID_ELECTRICITY, SERVICE_ID_PIPED_GAS].includes(rate.type) ||
      ((rate.type === SERVICE_ID_ELECTRICITY && usageType === USAGE_TYPE_LOW) ||
      (rate.type === SERVICE_ID_PIPED_GAS && usageType === USAGE_TYPE_LOW)
        ? !rate.lowRatesGSTExclusive || !rate.lowRatesGSTExclusive.length
        : !rate.standardRatesGSTExclusive ||
          !rate.standardRatesGSTExclusive.length),
    renderNothing
  ),
  withProps(({ usageType, rate, withGST }) => ({
    currentRates:
      [SERVICE_ID_ELECTRICITY, SERVICE_ID_PIPED_GAS].includes(rate.type) &&
      renderRates(usageType, rate, withGST),
  }))
)(DetailedRate);
