import { combineReducers } from "redux";
import Auth from "../AuthRedux";
import Login from "./LoginRedux";
import UserProfile from "./UserProfileRedux";
import Customer from "../CustomerJoin/CustomerRedux";
import LpgInfo from "../CustomerJoin/BottledGasRedux";
import Payment from "../CustomerJoin/PaymentRedux";
import Services from "../CustomerJoin/ServicesRedux";
import Property from "../CustomerJoin/PropertyRedux";
import Confirmation from "../CustomerJoin/ConfirmationRedux";
import BroadbandInfo from "../CustomerJoin/BroadbandRedux";
import Readonly from "../CustomerJoin/ReadonlyRedux";

export default combineReducers({
  Auth,
  Login,
  UserProfile,
  Customer,
  LpgInfo,
  Payment,
  Services,
  Property,
  Confirmation,
  BroadbandInfo,
  Readonly,
});
