import React from "react";
import "./ButtonCheckBox.scss";
import classNames from "classnames";
import Icon from "../Icon";

export interface ButtonCheckBoxProps {
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  id?: string;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactElement;
  iconClass?: string;
  text: string;
  className?: string;
  primaryOnWhite?: boolean;
  customStyle?: Record<string, unknown>;
}

const ButtonCheckBox = ({
  checked = false,
  disabled,
  name,
  id,
  handleClick,
  icon,
  iconClass,
  primaryOnWhite,
  text,
  className,
  customStyle,
}: ButtonCheckBoxProps) => {
  const buttonClass = classNames(
    className,
    "buttonCheckBox ",
    !checked ? "" : " buttonCheckBox_checked",
    {
      "button--primaryOnWhite": primaryOnWhite,
    }
  );

  const iconClassName = classNames(
    iconClass,
    "buttonCheckBox_withIcon",
    checked ? " buttonCheckBox_iconChecked" : ""
  );

  return (
    <button
      id={id}
      name={name}
      disabled={disabled}
      type="button"
      className={buttonClass}
      onClick={handleClick}
      tabIndex={0}
      style={customStyle}
    >
      {icon && (
        <span>
          <Icon icon={icon} className={iconClassName} />
        </span>
      )}

      {text}
    </button>
  );
};

export default ButtonCheckBox;
