import React from "react";
import classNames from "classnames";
import Icon from "../Icon";
import Loading from "../Loading";

import "./Button.scss";

const Button = (props) => {
  const buttonClass = classNames(props.className, {
    button: true,
    "button--ghost": props.theme === "ghost",
    "button--purple": props.theme === "purple",
    "button--primaryBlack": props.primaryBlack,
    "button--primaryOnDark": props.primaryOnDark,
    "button--primaryOnLight": props.primaryOnLight,
    "button--darkBorder": props.darkBorder,
    "button--redWithWhiteText": props.theme === "toggleConfirm",
    "button--redWithWhiteText--disabled":
      props.theme === "toggleConfirm" && props.disabled,
    "button--whiteWithPurpleText": props.theme === "toggleCancel",
    "button--inheritCase": props.inheritCase,
    "button--href": props.href,
    "button--primaryOnLight--disabled": props.primaryOnLight && props.disabled,
    "button--dense": props.dense,
  });

  if (props.href) {
    return (
      <a
        id={props.id}
        className={buttonClass}
        href={props.href}
        target={props.target}
        onClick={props.handleClick}
      >
        <span className="button__inner">
          {props.icon && (
            <span>
              <Icon icon={props.icon} className="button_icon" />
            </span>
          )}
          {props.text}
        </span>
      </a>
    );
  }

  // const type = 'Primary' | 'Secondary' | 'Tertiary' | 'Disabled' | 'Loading'

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      ref={props.buttonRef}
      id={props.id}
      name={props.name}
      disabled={props.disabled && !props.handleValidationClick}
      type={props.type}
      className={buttonClass}
      onClick={
        props.disabled && props.handleValidationClick
          ? props.handleValidationClick
          : props.handleClick
      }
      tabIndex={0}
      style={props.customStyle}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.icon && <Icon icon={props.icon} className="button_icon" />}

      {props.isLoading && (
        <div className="button_loader">
          <Loading isInContainer="true" />
        </div>
      )}

      <div className="button_textContainer">
        {props.text}
        {props.secondaryText && (
          <div className="button_secondaryText">{props.secondaryText}</div>
        )}
      </div>
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  theme: "reverseGhost",
};

export default Button;
