import React, { useCallback } from "react";
import ButtonCheckBox from "../ButtonCheckBox";
import "./ButtonCheckboxGroup.scss";

type CheckboxValue = string | number | boolean;

type CheckboxGroupValue = CheckboxValue[];

interface CheckboxItem {
  value: CheckboxValue;
  label: string;
}

export interface ButtonCheckboxGroupProps {
  items: CheckboxItem[];
  value?: CheckboxGroupValue;
  onChange(value: CheckboxGroupValue): void;
}

const ButtonCheckboxGroup = ({
  items,
  value = [],
  onChange,
}: ButtonCheckboxGroupProps) => {
  const isItemChecked = useCallback(
    (item: CheckboxItem) => value.includes(item.value),
    [value]
  );

  const handleItemClick = useCallback(
    (item: CheckboxItem) => () => {
      if (isItemChecked(item)) {
        onChange(value.filter((itemValue) => itemValue !== item.value));
      } else {
        onChange([...value, item.value]);
      }
    },
    [value, onChange, isItemChecked]
  );

  return (
    <div className="buttonCheckboxGroup">
      {items.map((item) => (
        <ButtonCheckBox
          key={item.label}
          checked={isItemChecked(item)}
          text={item.label}
          handleClick={handleItemClick(item)}
          className="buttonCheckboxGroup_item"
        />
      ))}
    </div>
  );
};

export default ButtonCheckboxGroup;
