import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  RefCallback,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export const AlertSectionServices = "Services";
export const AlertSectionPlans = "Plans";
export const AlertSectionPayment = "Payment";
export const AlertSectionFinal = "Final";

export interface Alert {
  text: string;
  header?: string;
  html?: boolean;
  isWarning?: boolean;
  section?: unknown;
  disableClose?: boolean;
  ref?: RefCallback<HTMLDivElement>;
}

export interface PartialAlert extends Partial<Alert> {
  text: string;
}

export const AlertContext = createContext<Alert[]>([]);
export const AlertSetterContext = createContext<
  Dispatch<SetStateAction<Alert[]>> | undefined
>(undefined);

export function useAlertContext() {
  return useContext(AlertContext);
}

export function useAlertSetterContext() {
  const setter = useContext(AlertSetterContext);
  if (!setter) {
    throw new Error("Expected SetAlertContext");
  }
  return setter;
}

export type AlertProviderProps = Record<string, unknown>;

export function AlertProvider({
  children,
}: PropsWithChildren<AlertProviderProps>) {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  return (
    <AlertContext.Provider value={alerts}>
      <AlertSetterContext.Provider value={setAlerts}>
        {children}
      </AlertSetterContext.Provider>
    </AlertContext.Provider>
  );
}

export function useAlerts(
  section: unknown = undefined
): [
  Alert[],
  (
    text: string | PartialAlert,
    isWarning?: boolean,
    ref?: Alert["ref"]
  ) => void,
  (alert: string | PartialAlert) => void
] {
  const allAlerts = useAlertContext();
  const alerts = useMemo(
    () => allAlerts.filter((alert) => alert.section === section),
    [section, allAlerts]
  );
  const setAlerts = useAlertSetterContext();
  const add = useCallback(
    (alert: string | PartialAlert, isWarning = false, ref?: Alert["ref"]) => {
      const builtAlert: Alert =
        typeof alert === "string"
          ? {
              text: alert,
              isWarning,
              ref,
              section,
            }
          : {
              isWarning,
              section,
              ...alert,
            };
      setAlerts((alerts) => {
        const found = alerts.find(
          (value) =>
            value.text === builtAlert.text &&
            value.section === builtAlert.section
        );
        if (found) return alerts;
        return alerts.concat(builtAlert);
      });
    },
    [setAlerts, section]
  );
  const remove = useCallback(
    (alert: string | PartialAlert) => {
      setAlerts((notes) => notes.filter(isNotMatchingAlert));

      function isNotMatchingAlert(other: Alert) {
        return !isMatchingAlert(other);
      }

      function isMatchingAlert(other: PartialAlert) {
        if (typeof alert === "string") {
          return other.text === alert && other.section === section;
        }
        return (
          alert === other ||
          (alert.section === other.section && alert.text === other.text)
        );
      }
    },
    [setAlerts, section]
  );
  return [alerts, add, remove];
}
