import React, { useCallback } from "react";
import ButtonCheckBox from "../ButtonCheckBox";
import "./ButtonRadioGroup.scss";

type RadioValue = string | number | boolean;

type RadioGroupValue = RadioValue;

interface RadioItem {
  value: RadioValue;
  label: string;
}

export interface ButtonRadioGroupProps {
  items: RadioItem[];
  value?: RadioGroupValue;
  onChange(value: RadioGroupValue): void;
}

const ButtonRadioGroup = ({
  items,
  value,
  onChange,
}: ButtonRadioGroupProps) => {
  const isItemChecked = useCallback((item: RadioItem) => value === item.value, [
    value,
  ]);

  const handleItemClick = useCallback(
    (item: RadioItem) => () => {
      onChange(item.value);
    },
    [onChange]
  );

  return (
    <div className="buttonRadioGroup">
      {items.map((item) => (
        <ButtonCheckBox
          key={item.label}
          checked={isItemChecked(item)}
          text={item.label}
          handleClick={handleItemClick(item)}
          className="buttonRadioGroup_item"
        />
      ))}
    </div>
  );
};

export default ButtonRadioGroup;
