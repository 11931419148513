import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { animated, useSpring } from "react-spring";
import _debounce from "lodash/debounce";
import classNames from "classnames";

import Icon from "../Icon";
import { ArrowRight } from "../../Assets/Icons";

import "./Tabs.scss";

const Tabs = (props) => {
  const [selectedTab, setSelectedTab] = useState(props.tabList[0].header);
  const listElement = useRef(null);
  const slideMultiplier = 400;

  const [handleSlide, setHandleSlide] = useState({
    listElementScrollWidth: 0,
    listElementWidth: 0,
    slideCount: 0,
  });

  const getResizeData = useCallback(() => {
    const stateObject = {
      listElementScrollWidth: listElement.current.scrollWidth,
      listElementWidth: listElement.current.parentElement.offsetWidth,
      slideCount:
        listElement.current.scrollWidth === listElement.current.offsetWidth
          ? 0
          : handleSlide.slideCount,
    };

    setHandleSlide(stateObject);
  }, [handleSlide.slideCount]);

  const debounceGetResizeData = useMemo(() => _debounce(getResizeData, 150), [
    getResizeData,
  ]);

  useEffect(() => {
    if (props.hasMadePaymentAttempt) {
      setSelectedTab(-1);
    }
  }, [props.hasMadePaymentAttempt]);

  useEffect(() => {
    window.addEventListener("resize", debounceGetResizeData);
    return () => {
      window.removeEventListener("resize", debounceGetResizeData);
    };
  });

  useEffect(() => {
    getResizeData();
  }, [getResizeData]);

  const [slideList, setSlideList] = useSpring(() => ({ x: 0 }));

  setSlideList({
    x:
      handleSlide.slideCount > 0
        ? handleSlide.listElementScrollWidth >=
          handleSlide.slideCount * slideMultiplier +
            handleSlide.listElementWidth
          ? -handleSlide.slideCount * slideMultiplier
          : -handleSlide.listElementScrollWidth + handleSlide.listElementWidth
        : 0,
  });

  const tabClickedHandler = (header) => {
    setSelectedTab(header);
    if (props.onClick) {
      props.onClick(header);
    }
  };

  const classes = classNames({
    tabs: true,
    "tabs--nopadding": props.noPadding,
    "tabs--overflow": props.withOverflow,
  });

  const displayContent = () => {
    const filteredContent = props.tabList.filter((tab) => {
      return tab.header === selectedTab;
    });

    if (props.hasMadePaymentAttempt) {
      return props.hasMadePaymentContent;
    }

    return filteredContent[0].content;
  };

  return (
    <div className={classes}>
      <div className="tabs_headerListContainer">
        {handleSlide.slideCount > 0 && (
          <animated.div
            className="tabs_iconArrow tabs_iconArrow--left"
            onClick={() =>
              handleSlide.slideCount > 1
                ? setHandleSlide({
                    ...handleSlide,
                    slideCount: handleSlide.slideCount - 1,
                  })
                : setHandleSlide({ ...handleSlide, slideCount: 0 })
            }
          >
            <Icon icon={<ArrowRight />} className="tabs_icon" />
          </animated.div>
        )}

        <animated.ul
          className="tabs_headerList"
          ref={listElement}
          style={{
            transform: slideList.x.interpolate((x) => `translateX(${x}px)`),
          }}
        >
          {props.tabList.map((tabItem, index) => (
            <li
              className={`tabs_headerListItem ${
                selectedTab === tabItem.header ? "active" : ""
              } ${
                props.tabList.length === 2
                  ? "tabs_headerListItem--doubleTab"
                  : ""
              }`}
              key={tabItem.header + index}
              role="tab"
              onClick={() => {
                if (selectedTab !== -1) {
                  tabClickedHandler(tabItem.header);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && selectedTab !== -1) {
                  tabClickedHandler(tabItem.header);
                }
              }}
            >
              {tabItem.header}
            </li>
          ))}
        </animated.ul>

        {handleSlide.listElementScrollWidth >
          handleSlide.slideCount * slideMultiplier +
            handleSlide.listElementWidth && (
          <animated.div
            className="tabs_iconArrow tabs_iconArrow--right"
            onClick={() =>
              setHandleSlide({
                ...handleSlide,
                slideCount: handleSlide.slideCount + 1,
              })
            }
          >
            <Icon icon={<ArrowRight />} className="tabs_icon" />
          </animated.div>
        )}
      </div>
      <div className="tabs_contentContainer">{displayContent()}</div>
    </div>
  );
};

export default Tabs;
