import axios, { AxiosRequestConfig } from "axios";
import _now from "lodash/now";
import { RatesUtil } from "react-components";
import { withUserType } from "../RatesUtils";
import * as actionTypes from "./actionTypes";
import { rehydratedCustomerData } from "./CustomerJoin/CustomerActions";
import { clearSessionId } from "./PersistActions";
import { updateMyDetails } from "./CustomerJoin/MyDetailsActions";
import { updateSubmittingJourney } from "./CustomerJoin/JoinDetailsAction";
import * as Analytics from "../../Utils/analytics";
import {
  USAGE_TYPE_STANDARD,
  USAGE_TYPE_LOW,
  BASIC_PLAN,
  SERVICE_ID_PIPED_GAS,
  MOVE_JOURNEY_TYPE,
} from "../../Config/Constants";
import { AddressDetailsFixture } from "../Fixtures/express/AddressDetailsResponse";
import { IdentityCheckFixture } from "../Fixtures/express/IdentityCheckResponse";
import { ElectricityLookupFixture } from "../Fixtures/express/ElectricityLookupResponse";
import { IcpLookupFixture } from "../Fixtures/express/IcpLookupResponse";
import { PriceFixture } from "../Fixtures/express/PriceResponse";
import { Express as Constants } from "../../Config/Constants";
import { triggerEPValues } from "../../Utils/analytics";
import noop from "nop";
import {
  CheckIdentityPath,
  CreateSaveForLaterPath,
  ElectricityLookupPath,
  ElectricityRegistryICPDetailsPath,
  FindAddressPath,
  FormsGenericPath,
  GasLookupPath,
  GetBillingEligibilityPath,
  GetGasRegistryICPDetailsPath,
  GetSiteCoreContentPath,
  PlansPath,
  PricePath,
  StatePath,
  SubmitJourneyPath,
  ValidatePromoCodePath,
} from "@contact/data-access";

const formKeyNPS = "nps.generic.feedback";

export const stateUrl = process.env.NX_BASE_URL + StatePath.v2 + "/";
const submitUrl = process.env.NX_BASE_URL + SubmitJourneyPath.v2 + "/";
export const saveForLaterUrl =
  process.env.NX_BASE_URL + CreateSaveForLaterPath.v1;
const npsURL = process.env.NX_BASE_URL + FormsGenericPath.v1 + "/";
const addressDetailsUrl = process.env.NX_BASE_URL + FindAddressPath.v1 + "/";
const electricityLookupUrl =
  process.env.NX_BASE_URL + ElectricityLookupPath.v1 + "/";
const icpLookupUrl =
  process.env.NX_BASE_URL + ElectricityRegistryICPDetailsPath.v1 + "/";
const priceUrl = process.env.NX_BASE_URL + PricePath.v2;
const plansUrl = process.env.NX_BASE_URL + PlansPath.v1;
const identityCheckUrl = process.env.NX_BASE_URL + CheckIdentityPath.v1 + "/";
const promoCodeUrl = process.env.NX_BASE_URL + ValidatePromoCodePath.v2;
const sitcoContentUrl =
  process.env.NX_BASE_URL + GetSiteCoreContentPath.v2 + "/";
const billingEligibilityUrl =
  process.env.NX_BASE_URL + GetBillingEligibilityPath.v2;
const gasAddressSearchUrl = process.env.NX_BASE_URL + GasLookupPath.v1;
const gasIcpSearchUrl =
  process.env.NX_BASE_URL + GetGasRegistryICPDetailsPath.v1;

export const config: Partial<AxiosRequestConfig> = {
  headers: {
    "x-api-key": process.env.NX_X_API_KEY,
    "Content-Type": "application/json",
  },
};

export const baseAxiosConfig: Partial<AxiosRequestConfig> = {
  baseURL: process.env.NX_BASE_URL,
  timeout: 60000,
  ...config,
};

const basicPlanParams = {
  division: "01",
  billingClass: "RESI",
};

export const apiError = () => ({
  type: actionTypes.API_ERROR,
});

export const updateMyDetailsFail = (is404 = false) => {
  if (is404) {
    return [clearSessionId(), apiError()];
  }
  return apiError();
};

export const getBillinEligibility = (data) => {
  return axios.post(billingEligibilityUrl, data, config);
};

export const updateMyDetailsSuccess = () => {
  return { type: actionTypes.API_SUCCESS };
};

export const submitSuccess = () => ({
  type: actionTypes.API_SUBMIT_SUCCESS,
});

export const callAPI = () => {
  return { type: actionTypes.CALL_API };
};

export const getApiState = () => async (dispatch, getState) => {
  const sessionId = getState().Auth.sessionID;
  if (sessionId) {
    dispatch(rehydratedCustomerData(false));
    dispatch(callAPI());

    try {
      const { data } = await axios.get(stateUrl + sessionId, config);
      dispatch(updateMyDetails(data));
      dispatch(updateMyDetailsSuccess());
      dispatch(rehydratedCustomerData(true));
    } catch (error) {
      dispatch(
        updateMyDetailsFail(error.response && error.response.status === 404)
      );
    }
  } else {
    dispatch(updateMyDetailsFail());
  }
};

export function isPendingSaveApiState() {
  return { type: actionTypes.API_STATE_PENDING_SAVE };
}

export const getUpdateApiStateRequestBody = (state) => ({
  ...state,
  CreditCheckInfo: state.CreditCheck?.data,
  PropertyInfo: state.Property?.PropertyInfo || state.PropertyInfo,
  MedicalInfo: state.Property?.MedicalInfo || state.MedicalInfo,
  MoveInfo: state.Property?.MoveInfo || state.MoveInfo,
});

export const saveApiState = (
  newData = {},
  submit = false,
  isCustomerJoin = false
) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (!submit && state.isCallingAPI) {
      // Leave this for later
      return dispatch(isPendingSaveApiState());
    }
    const data = {
      ...getUpdateApiStateRequestBody(state),
      ...newData,
      sessionID: state.Auth.sessionID,
      deleteAfter: state.Auth.deleteAfter,
    };
    dispatch(callAPI());
    try {
      await axios.put(stateUrl, data, config);
      if (submit) {
        return dispatch(submitToJourneyApi(undefined, isCustomerJoin));
      } else {
        dispatch(updateMyDetailsSuccess());
      }
    } catch (error) {
      dispatch(
        updateMyDetailsFail(error.response && error.response.status === 404)
      );
    }
  };
};

export const createApiState = (apiData) => {
  return (dispatch, getState) => {
    const state = getState();
    const data = {
      ...apiData,
      sessionID: state.Auth.sessionID,
      deleteAfter: state.Auth.deleteAfter,
    };
    dispatch(callAPI());
    return axios
      .post(stateUrl, data, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        dispatch(
          updateMyDetailsFail(error.response && error.response.status === 404)
        );
      });
  };
};

const withFixture = (fixture, apiCall) => (requestPayload, axiosOptions = {}) =>
  process.env.NX_USE_FIXTURES
    ? Promise.resolve(fixture)
    : apiCall(requestPayload, axiosOptions);

export const getAddressDetails = withFixture(AddressDetailsFixture, (id) =>
  axios.get(addressDetailsUrl + id, config)
);

export const getElectricityDetails = withFixture(
  ElectricityLookupFixture,
  (requestPayload) =>
    axios.post(
      electricityLookupUrl,
      {
        ...requestPayload,
        format: "websphere",
      },
      config
    )
);

const formatDateToAPIString = (date) => {
  const d = new Date(date);
  return `${d.getDate()}${d.getMonth() + 1}${d.getFullYear()}`;
};
export const getGasRates = (gasIcp, plan) => {
  return getGasIcp({ icp: gasIcp }).then(({ data }) => {
    const payload = {
      packCode: plan.PackCode,
      icpNumber: data.icp,
      startDate: formatDateToAPIString(plan.StartDate), // NOTE: format is a bit strange: DDMMYYYY, no separators
      billingClass: "RESI", // NOTE: Hardcoded by design
      division: "02", // NOTE: Hardcoded, 02 = GAS, 01 = ELEC
      campaignId: plan.CampaignId,
      priceCategoryCode: data.priceCategoryCode,
      gxpNumber: data.gxpNumber,
      networkCode: data.networkCode,
    };

    return Promise.all([
      axios.post(
        priceUrl,
        { ...payload, electricityUserType: USAGE_TYPE_LOW },
        config
      ),
      axios.post(
        priceUrl,
        { ...payload, electricityUserType: USAGE_TYPE_STANDARD },
        config
      ),
    ]).then(([{ data: lowPricing }, { data: standardPricing }]) => {
      const rates = RatesUtil.getRates([
        {
          Rates: [
            ...withUserType(
              lowPricing.priceList.filter((rate) => rate.Charge > 0),
              USAGE_TYPE_LOW
            ),
            ...withUserType(
              standardPricing.priceList.filter((rate) => rate.Charge > 0),
              USAGE_TYPE_STANDARD
            ),
            ...withUserType(
              lowPricing.priceList.filter((rate) => rate.Charge > 0),
              USAGE_TYPE_LOW,
              true
            ),
            ...withUserType(
              standardPricing.priceList.filter((rate) => rate.Charge > 0),
              USAGE_TYPE_STANDARD,
              true
            ),
          ],
          Service: SERVICE_ID_PIPED_GAS,
        },
      ]);

      return rates;
    });
  });
  // TODO: This needs to get rates via POST /price endpoint
  // return axios.post(priceEstimationUrl, requestPayload, config)
  //   .then((response) => {
  //     console.log("API :: getGasRates :: response", response.data.ServiceRates.find(item => item.Service === 'GAS'))
  //     return response.data.ServiceRates.find(item => item.Service === 'GAS');
  //   })
};

export const getElecRegion = (requestPayload) => {
  return noop.call(requestPayload);
};

export const getIcpDetails = withFixture(
  IcpLookupFixture,
  (requestPayload, axiosOptions) =>
    axios.post(icpLookupUrl, requestPayload, {
      ...config,
      ...axiosOptions,
    })
);

export const getPrice = withFixture(
  PriceFixture,
  (requestPayload, axiosOptions) =>
    axios.post(priceUrl, requestPayload, {
      ...config,
      ...axiosOptions,
    })
);

export const getRates = (icp, planId, axiosOptions = {}) => {
  let regionCode = "";
  return getIcpDetails({ icp }, axiosOptions)
    .then((response) =>
      Promise.all([
        {
          icpNumber: icp,
          gxpNumber: response.data.icp.gxpNumber,
          networkCode: response.data.icp.networkCode,
          priceCategoryCode: response.data.icp.priceCategoryCode,
          registerContentCodes: response.data.icp.registerContentCodes,
          regionCode: response.data.icp.regionCode,
          startDate: _now().toString(),
          ...basicPlanParams,
        },
        axios.get(plansUrl, {
          ...config,
          ...axiosOptions,
        }),
      ])
    )
    .then(([request, response]) => {
      regionCode = request.regionCode;
      if (response.data && response.data.Plans) {
        const plan = response.data.Plans.find((plan) => plan.Id === planId);
        const basicPlan = response.data.Plans.find(
          (plan) => plan.Id === BASIC_PLAN
        );
        const currentPlan = plan ?? basicPlan;
        return {
          ...request,
          campaignId: currentPlan.CampaignId,
          packCode: currentPlan.PackCode,
        };
      }
      return request;
    })
    .then((request) =>
      Promise.all([
        getPrice(
          { ...request, electricityUserType: USAGE_TYPE_STANDARD },
          axiosOptions
        ),
        getPrice(
          { ...request, electricityUserType: USAGE_TYPE_LOW },
          axiosOptions
        ),
      ])
    )
    .then((response) => {
      triggerEPValues(icp, response.length !== 0, regionCode);
      return response;
    });
};

export const getDefaultPlans = (axiosOptions = {}) => {
  return axios
    .get(plansUrl, {
      ...config,
      ...axiosOptions,
    })
    .then((response) => {
      return response.data.Plans;
    });
};

export const validatePromoCode = (promoCode) => {
  const url = promoCodeUrl + `?promoCode=${promoCode}`;
  return axios.get(url, config).then((response) => {
    return response.data;
  });
};

export const getGasAddress = (queryParams) => {
  return axios.get(gasAddressSearchUrl, { ...config, params: queryParams });
};

export const getGasIcp = (queryParams) => {
  return axios.get(gasIcpSearchUrl, { ...config, params: queryParams });
};

export const getSitcorePlans = (agent, journeyType, axiosOptions = {}) => {
  const urlCustomer =
    sitcoContentUrl +
    "ijoin-react-app%2Fhome%2Fresidential%2Ffind-a-plan-react";
  const urlAgentJoin =
    sitcoContentUrl +
    "ijoin-react-app%2Fhome%2Fresidential%2Ffind-a-plan-csr-react";
  const urlAgentMove =
    sitcoContentUrl +
    "ijoin-react-app%2Fhome%2Fresidential%2Fmake-changes-csr-react";
  const url = agent
    ? journeyType === MOVE_JOURNEY_TYPE
      ? urlAgentMove
      : urlAgentJoin
    : urlCustomer;
  return axios.get(url, {
    ...config,
    ...axiosOptions,
  });
};

export const submitToJourneyApi = (
  stateData = undefined,
  isCustomerJoin = false
) => {
  return (dispatch, getState) => {
    const state = getState();
    const apiData = {
      sessionID: state.Auth.sessionID,
      customerType: "Residential",
      journeyType: state.JourneyType || state.Confirmation.JourneyType,
    };

    return axios
      .post(
        submitUrl,
        apiData,
        isCustomerJoin
          ? config
          : {
              ...config,
              headers: {
                ...config.headers,
                "access-token": state.Login.token,
              },
            }
      )
      .then(() => {
        dispatch(updateSubmittingJourney(false));
        if (state.Confirmation.JourneyType === Constants.expressJourneyType) {
          Analytics.triggerExpressMoveSubmitted(
            (stateData || state).MedicalInfo.HasVulnerablePerson,
            (stateData || state).MedicalInfo.HasMedicalDependant,
            (stateData || state).PropertyInfo.Hazards
          );
        }
        dispatch(submitSuccess());
      })
      .catch((error) => {
        dispatch(updateSubmittingJourney(false));
        dispatch(
          updateMyDetailsFail(error.response && error.response.status === 404)
        );
      });
  };
};
export const checkIdentity = withFixture(IdentityCheckFixture, (stateData) =>
  axios.post(identityCheckUrl, stateData, config)
);

export const deleteStateApiSessionId = () => (dispatch, getState) => {
  const sessionId = getState().Auth.sessionID;
  if (sessionId) {
    return axios.delete(stateUrl + sessionId, config);
  }
};

export const submitToNPSApi = (apiData, formKey = formKeyNPS) => {
  return () => {
    const data = {
      formsKey: formKey,
      retainToken: "",
      enterPrizeDraw: "false",
      ...apiData,
    };

    axios
      .post(npsURL + formKey, data, config)
      .then(() => {
        if (apiData.preferredChannel) {
          Analytics.triggerAsyncNPSSubmit(apiData);
        } else {
          Analytics.triggerNPSSubmit(apiData.score, apiData.scoreComments);
        }
      })
      .catch(() => {
        // go silent and do not notify customer.
      });
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createBusinessAccountNumber(data) {
  return noop();
}
