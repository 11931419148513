import * as actionTypes from "../../Actions/actionTypes";
import { DateUtil } from "react-components";
import { DATE_FORMAT } from "../../../Config/Constants";

export const initialState = {
  BroadbandId: "",
  BroadbandFullName: "",
  HasBroadbandProvider: false,
  BroadbandProvider: "",
  BroadbandProviderOther: null,
  AccountHolderName: "",
  AccountNumber: "",
  ModemDeliveredToSameAddress: true,
  DeliveryAddress: "",
  Moniker: "",
  AddPhoneLine: false,
  HomePhoneNumber: "",
  PhoneLineOptions: [],
  BroadbandConnectionDate: DateUtil.convertDateInstanceToFormat(
    new Date(),
    DATE_FORMAT
  ),
  ModemSupplier: "",
  ModemMake: "",
};

const updateBroadband = (state, broadband) => {
  return { ...state, ...broadband };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_BROADBAND:
      return updateBroadband(state, action.broadband);

    default:
      return state;
  }
};

export default reducer;
