import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { PlansApiResponse } from './models';
import { PlansPath, PlansVersion } from '..';

export interface PlansAPIRequestParams {
  journey: 'Join' | 'Add' | 'Move' | 'Change';
  type: 'RESI' | 'BUSI';
}

export const getPlans = (
  version: PlansVersion,
  journey: PlansAPIRequestParams['journey'],
  type: PlansAPIRequestParams['type'],
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<PlansApiResponse>(PlansPath[version], {
    params: {
      journey,
      type,
    },
  });
};
