import { AxiosRequestConfig } from 'axios';
import { GetKenticoContentPath, GetKenticoContentVersion } from './apiPaths';
import { baseInstance } from './base-service';

export const getKenticoContent = <T>(
  version: GetKenticoContentVersion,
  types?: string[],
  ids?: string[],
  config?: AxiosRequestConfig
) =>
  baseInstance(config).get<T>(GetKenticoContentPath[version], {
    params: {
      contentTypes: types?.join(','),
      id: ids?.join(','),
    },
  });
