import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../Stores/CustomerStore";

interface SnapshotAction {
  id: string;
  hash: string;
}

const initialState: Record<string, string> = {};

export const snapshotSlice = createSlice({
  name: "snapshot",
  initialState,
  reducers: {
    store(state, action: PayloadAction<SnapshotAction>) {
      state[action.payload.id] = action.payload.hash;
    },
    remove(state, action: PayloadAction<SnapshotAction["id"]>) {
      delete state[action.payload];
    },
    update(state, action: PayloadAction<typeof initialState>) {
      return action.payload;
    },
  },
});

export default snapshotSlice.reducer;

export const {
  store: storeSnapshot,
  remove: removeSnapshot,
  update: updateSnapshot,
} = snapshotSlice.actions;

export const getSnapshotHashById = createSelector(
  (state: RootState) => state.Snapshot,
  (_, snapshotId) => snapshotId,
  (snapshotSlice, snapshotId: string) => {
    return snapshotSlice?.[snapshotId];
  }
);
