export const ServiceModalConstants = {
  buttonTextPrimary: "Cancel",
  buttonTextSecondary: "Use these details",
  pipedGasButtonTextPrimary: "Continue without ICP",
  pipedGasMultipleIcpButtonTextPrimary: "Cancel",
  pipedGasButtonTextSecondary: "Confirm",
  icpMaxLength: 15,
  addressPlaceHolder: "Enter your address",
  addressInvalidMessage: "Address is invalid",
  StreetNumber: "Street number",
  StreetName: "Street name",
  SuburbTown: "Suburb or town",
  Region: "Region",
  IcpNumberRequiredMsg: "ICP number is required",
  IcpNumberInvalidMsg: "Invalid ICP, please enter 15 character ICP",
  IcpNumberNotFoundMsg: "Sorry, ICP not found, please check your ICP number",
  MultipleIcpPlaceHolderMessage:
    "Please confirm the correct ICP from below list",
  MultipleIcpLabel: "Address",
  errorMessage: "Something went wrong. Please try again.",
  pipedGasIcpConfirmTitle: "Sorry, we couldn't find a match for your Piped Gas",
  pipedGasMultipleIcpConfirmTitle: "Please confirm your Piped Gas ICP",
  pipedGasIcpConfirmSubTitle:
    "Please confirm your Piped Gas ICP number below. This will be located on your energy bill or gas meter.",
  pipedGasMultipleIcpConfirmSubTitle:
    "We need to confirm that we set up the right address",
  pipedGasNoIcpLink: "Address not on this list?",
  pipedGasIcpInfoText:
    "Cant find your ICP? No problem, you can continue without it.",
  icpNumberLabel: "ICP number",
};

export const ServiceModalPipedGasRegions = [
  "Auckland",
  "Bay of Plenty",
  "Gisborne",
  "Hawkes Bay",
  "Manawatu",
  "Northland",
  "Taranak",
  "Waikato",
  "Wanganui",
  "Wellington",
];
