import * as React from "react";
import Icon from "../Icon";

const StepListItem = ({ name = undefined, icon, title, children }) => {
  return (
    <li name={name} className="stepList_item">
      <span className="stepList_item_iconCon">
        <Icon icon={icon} className="stepList_item_icon" />
      </span>
      <div className="stepList_item_content">
        <h3>{title}</h3>
        <div className="stepList_item_content--items">{children}</div>
      </div>
    </li>
  );
};

export default StepListItem;
