import * as actionTypes from "../../Actions/actionTypes";
import { reducerPropUpdater } from "../../utils";
import { STANDARD_USER } from "../../../Config/Constants";

export const initialState = {
  PropertyInfo: {
    Address: null,
    QASAddress: null,
    AddressSameAsPostalAddress: true,
    Moniker: null,
    ElectricityMeterType: null,
    ElectricityICP: null,
    GasICP: null,
    ElectricityUserType: STANDARD_USER,
    Hazards: [],
    HazardsOther: null,
    HazardsDog: null,
    HasDogs: false,
    WithAnotherSupplier: false,
    OwnsProperty: false,
    IsBach: false,
    HaveFinalMeterReading: false,
    FinalElectricityMeterRead: null,
    FinalGasMeterRead: null,
    SendMeterReader: false,
  },

  MedicalInfo: {
    HasMedicalDependant: undefined,
    CriticalMedicalEquipment: null,
    MedicalDependantFirstName: null,
    MedicalDependantLastName: null,
    MedicalDependantEMailAddress: null,
    MedicalDependantPhone: null,
    MedicalDependantSameAsAccountHolder: true,
    ContactHealthPractitioner: false,
    PracticeName: null,
    PracticePhone: null,
    PractitionerName: null,
    HasVulnerablePerson: undefined,
    VulnerableType: null,
    VulnerableText: null,
  },

  MoveInfo: {
    IsMovingHouse: false,
    AccountStart: undefined,
    MoveInDate: undefined,
    MoveOutDate: undefined,
    MoveFromAddress: undefined,
    Situation: undefined,
    SituationOtherDetails: "",
  },
  customerContracts: null,
};

const updateProp = reducerPropUpdater("Property");

const updateProperty = (state, property) => {
  const newPropertyInfo = {
    ...state.PropertyInfo,
    ...(property.PropertyInfo || {}),
  };
  const newMedicalInfo = {
    ...state.MedicalInfo,
    ...(property.MedicalInfo || {}),
  };
  const newMoveInfo = { ...state.MoveInfo, ...(property.MoveInfo || {}) };
  return {
    ...state,
    ...property,
    PropertyInfo: newPropertyInfo,
    MedicalInfo: newMedicalInfo,
    MoveInfo: newMoveInfo,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROP:
      return updateProp(state, action.propName, action.value);

    case actionTypes.UPDATE_PROPERTY:
      return updateProperty(state, action.property);

    case actionTypes.UPDATE_PROPERTY_SITUATION:
      return {
        ...state,
        MoveInfo: {
          ...state.MoveInfo,
          Situation: action.propertySituation,
        },
      };

    default:
      return state;
  }
};

export default reducer;
