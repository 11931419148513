import React from "react";

import classNames from "classnames";

import "./LayoutColumn.scss";

const LayoutColumn = ({
  top = undefined,
  bottom = undefined,
  hideBottom = undefined,
  hideColumn = undefined,
  reverseColumn = undefined,
  reverseTabletWidth = undefined,
  topIsSection = undefined,
  bottomIsImageContainer = undefined,
  fullHeight = undefined,
}) => {
  const layoutColumn = classNames({
    layoutColumn: true,
    "layoutColumn--reverseColumn": reverseColumn,
    "layoutColumn--hideColumn": hideColumn,
    "layoutColumn--hideBottom": hideBottom,
    "layoutColumn--reverseTabletWidth": reverseTabletWidth,
    "layoutColumn--fullHeight": fullHeight,
  });

  const renderTop = () => {
    if (topIsSection) {
      return (
        <section className="layoutColumn_topContainer">
          {top}
          <div className="layoutColumn_colBackground" />
        </section>
      );
    }

    return <div className="layoutColumn_topContainer">{top}</div>;
  };

  const renderBottom = () => {
    if (bottomIsImageContainer) {
      return <div className="layoutColumn_bottomImageContainer">{bottom}</div>;
    }

    return <div className="layoutColumn_bottomContainer">{bottom}</div>;
  };

  return (
    <div className={layoutColumn}>
      {renderTop()}
      {renderBottom()}
    </div>
  );
};

export default LayoutColumn;
