// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Importing this style first resolves ordering issues later down the track 🤦
import "./FindPlan/FindPlan.scss";

import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { withErrorBoundary } from "../Utils/enhancers";
import { Loading } from "../react-components";
import { AlertProvider } from "../Utils/Alert";

import AppError from "./AppError";
import NpsApp from "./NpsApp";

const CustomerJoinJourney = lazy(() => import("./CustomerJoinJourney"));

const App = () => {
  return (
    <BrowserRouter>
      <AlertProvider>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/residential" component={CustomerJoinJourney} />
            <Route path="/asyncsurvey" component={NpsApp} />
            <Route path="/404-not-found" render={() => "Not Found"} />
            <Route
              path="/"
              component={() => {
                window.location.href =
                  process.env.NX_EXPRESS_BASE_URL + "/404-not-found";
                return null;
              }}
            />
          </Switch>
        </Suspense>
      </AlertProvider>
    </BrowserRouter>
  );
};

export default withErrorBoundary((message) => <AppError message={message} />)(
  App
);
