// v1
export const CcRefundSubmitPath = {
  v1: 'cc-remediation/submit',
};
export type CcRefundSubmitVersion = keyof typeof CcRefundSubmitPath;
export const AddressFinderVerificationPath = {
  v1: '/address-finder/verification',
};
export type AddressFinderVerificationVersion = keyof typeof AddressFinderVerificationPath;
export const GetElectricityRegistryManualSearchPath = {
  v1: '/electricity-registry/manual-search',
};
export type GetElectricityRegistryManualSearchVersion = keyof typeof GetElectricityRegistryManualSearchPath;
export const GetSmartFuelCheckBalancePath = { v1: '/smart-fuel/checkbalance' };
export type GetSmartFuelCheckBalanceVersion = keyof typeof GetSmartFuelCheckBalancePath;
export const CreateSaveForLaterPath = { v1: '/save-for-later' };
export type CreateSaveForLaterVersion = keyof typeof CreateSaveForLaterPath;
export const GetGasRegistryICPDetailsPath = { v1: '/gas-registry/icp-search' };
export type GetGasRegistryICPDetailsVersion = keyof typeof GetGasRegistryICPDetailsPath;
export const CheckIdentityPath = { v1: '/identity-check' };
export type CheckIdentityVersion = keyof typeof CheckIdentityPath;
export const GasLookupPath = { v1: '/gas-registry/address-search' };
export type GasLookupVersion = keyof typeof GasLookupPath;
export const FindAddressPath = { v1: '/address-finder/details' };
export type FindAddressVersion = keyof typeof FindAddressPath;
export const AddressCachedDetailsPath = { v1: '/address-cached/details' };
export type AddressCachedDetailsVersion = keyof typeof AddressCachedDetailsPath;
export const MobileVersionPath = { v1: '/mobile-version' };
export type MobileVersionVersion = keyof typeof MobileVersionPath;
export const ActionTrackingPath = { v1: '/action-tracking' };
export type ActionTrackingVersion = keyof typeof ActionTrackingPath;
export const FormsGenericPath = { v1: '/forms/generic' };
export type FormsGenericVersion = keyof typeof FormsGenericPath;
export const FormsGenericBroadbandXsellPath = {
  v1: '/forms/generic/broadband-xsell.csr',
};
export type FormsGenericBroadbandXsellVersion = keyof typeof FormsGenericBroadbandXsellPath;
export const FormsSubmitPath = { v1: '/forms/submit' };
export type FormsSubmitVersion = keyof typeof FormsSubmitPath;
export const MoveAddMovePath = { v1: '/move-add/move' };
export type MoveAddMoveVersion = keyof typeof MoveAddMovePath;
export const MoveAddAddPath = { v1: '/move-add/add' };
export type MoveAddAddVersion = keyof typeof MoveAddAddPath;
export const SearchAddressPath = { v1: '/address-cached/search' };
export type SearchAddressVersion = keyof typeof SearchAddressPath;
export const ContentSyncPath = { v1: '/content-sync' };
export type ContentSyncVersion = keyof typeof ContentSyncPath;
export const ElectricityRegistryICPDetailsPath = {
  v1: '/electricity-registry/icp-lookup',
};
export type ElectricityRegistryICPDetailsVersion = keyof typeof ElectricityRegistryICPDetailsPath;
export const InteractiveBillDetailPath = { v1: '/interactive-bill' };
export type InteractiveBillDetailVersion = keyof typeof InteractiveBillDetailPath;
export const PlansPath = { v1: '/plans' };
export type PlansVersion = keyof typeof PlansPath;
export const PushNotificationUnregisterPath = {
  v1: '/push-notifications/unregister',
};
export type PushNotificationUnregisterVersion = keyof typeof PushNotificationUnregisterPath;
export const PushNotificationRegisterPath = {
  v1: '/push-notifications/register',
};
export type PushNotificationRegisterVersion = keyof typeof PushNotificationRegisterPath;
export const UpdateNotificationPath = {
  v1: '/notifications/update-notification',
};
export type UpdateNotificationVersion = keyof typeof UpdateNotificationPath;
export const ElectricityLookupPath = {
  v1: '/electricity-registry/electricity-lookup',
};
export type ElectricityLookupVersion = keyof typeof ElectricityLookupPath;
export const FeatureFlagsPath = { v1: '/feature-flags' };
export type FeatureFlagsVersion = keyof typeof FeatureFlagsPath;
export const GetNotificationsPath = { v1: '/notifications/get-notifications' };
export type GetNotificationsVersion = keyof typeof GetNotificationsPath;

export type GetJourneyDataVersion = keyof typeof GetJourneyDataPath;
export const GetNotificationDismissedPath = {
  v1: (messageId: string) => `/notification/${messageId}/Dismissed`,
};
export type GetNotificationDismissedVersion = keyof typeof GetNotificationDismissedPath;
export const GetNotificationClickedPath = {
  v1: (messageId: string) => `/notification/${messageId}/Clicked`,
};
export type GetNotificationClickedVersion = keyof typeof GetNotificationClickedPath;
export const GetNotificationPath = {
  v1: (businessAgreement: string) => `/notification/${businessAgreement}`,
};
export type GetNotificationVersion = keyof typeof GetNotificationPath;
export const RollOffFeedbackPath = { v1: '/rolloff/feedback' };
export type RollOffFeedbackVersion = keyof typeof RollOffFeedbackPath;
export const ConfigPath = {
  v1: (application: string, profile: string) =>
    `/config/v1/${application}/${profile}`,
};
export type ConfigVersion = keyof typeof ConfigPath;

// v2
export const GetMeterAccessPath = { v2: '/meter-key/v2' };
export type GetMeterAccessVersion = keyof typeof GetMeterAccessPath;
export const GetBroadbandOfferingsPath = { v2: '/devoli/v2/provide-address' };
export type GetBroadbandOfferingsVersion = keyof typeof GetBroadbandOfferingsPath;
export const GetBroadbandOfferingsByPlansPath = {
  v2: '/devoli/v2/provide-address/plans',
};
export type GetBroadbandOfferingsByPlansVersion = keyof typeof GetBroadbandOfferingsByPlansPath;
export const BroadbandLookupPath = { v2: '/devoli/v2/search-address' };
export type BroadbandLookupVersion = keyof typeof BroadbandLookupPath;
export const BroadbandPath = { v2: '/devoli/v2/broadband' };
export type BroadbandVersion = keyof typeof BroadbandPath;
export const BroadbandProvidersPath = { v2: '/devoli/v2/providers' };
export type BroadbandProvidersVersion = keyof typeof BroadbandProvidersPath;
export const BroadbandUsagePath = { v2: '/devoli/v2/bbusage' };
export type BroadbandUsageVersion = keyof typeof BroadbandUsagePath;
export const BroadbandUsageWeeklyDifferencePath = {
  v2: '/devoli/v2/bbusage/weekly-difference',
};
export type BroadbandUsageWeeklyDifferenceVersion = keyof typeof BroadbandUsageWeeklyDifferencePath;

// TODO: Remove this one once the roll off content is redirected to Kentico
export const GetSiteCoreContentPath = { v2: '/crm/v2/content' };
export type GetSiteCoreContentVersion = keyof typeof GetSiteCoreContentPath;

export const GetKenticoContentPath = { v1: '/content' };
export type GetKenticoContentVersion = keyof typeof GetKenticoContentPath;
export const GetBillingEligibilityPath = { v2: '/billing-eligibility/v2' };
export type GetBillingEligibilityVersion = keyof typeof GetBillingEligibilityPath;
export const ValidatePromoCodePath = { v2: '/crm/v2/validate-promo' };
export type ValidatePromoCodeVersion = keyof typeof ValidatePromoCodePath;
export const PromotionsPath = {
  v2: (promoCode: string) => `/promotions/${promoCode}`,
};
export type PromotionsVersion = keyof typeof PromotionsPath;
export const PricePath = { v2: '/price/v2' };
export type PriceVersion = keyof typeof PricePath;
export const RewardsPath = { v2: '/rewards/v2' };
export type RewardsVersion = keyof typeof RewardsPath;
export const TransactionsPath = { v2: '/transactions/v2' };
export type TransactionsVersion = keyof typeof TransactionsPath;
export const InvoicePath = { v2: '/transactions/v2/invoice' };
export type InvoiceVersion = keyof typeof InvoicePath;
export const CustomerPath = { v2: '/customer/v2' };
export type CustomerVersion = keyof typeof CustomerPath;
export const GetAccountsPath = { v2: '/accounts/v2' };
export type GetAccountsVersion = keyof typeof GetAccountsPath;
export const AasfPath = { v2: '/aasf/v2' };
export type AasfVersion = keyof typeof AasfPath;
export const BusinessPartnerUpdateDetailsPath = {
  v2: '/business-partner/v2/updateDetails',
};
export type BusinessPartnerUpdateDetailsVersion = keyof typeof BusinessPartnerUpdateDetailsPath;
export const DirectDebitsValidatePath = { v2: '/direct-debits/v2/validate' };
export type DirectDebitsValidateVersion = keyof typeof DirectDebitsValidatePath;
export const DirectDebitsGetDebitsPath = { v2: '/direct-debits/v2/get-debits' };
export type DirectDebitsGetDebitsVersion = keyof typeof DirectDebitsGetDebitsPath;
export const DirectDebitsSetupPath = { v2: '/direct-debits/v2/setup' };
export type DirectDebitsSetupVersion = keyof typeof DirectDebitsSetupPath;
export const DirectDebitsCancelPath = { v2: '/direct-debits/v2/cancel' };
export type DirectDebitsCancelVersion = keyof typeof DirectDebitsCancelPath;
export const RegistrationRegisterPath = { v2: '/registration/v2/register' };
export type RegistrationRegisterVersion = keyof typeof RegistrationRegisterPath;
export const LoginRefreshPath = { v2: '/login/v2/refresh' };
export type LoginRefreshVersion = keyof typeof LoginRefreshPath;
export const BusinessAgreementUpdateDetailsPath = {
  v2: '/business-agreement/v2/updateDetails',
};
export type BusinessAgreementUpdateDetailsVersion = keyof typeof BusinessAgreementUpdateDetailsPath;
export const MessagesConversationsPath = { v2: '/messages/v2/conversations' };
export type MessagesConversationsVersion = keyof typeof MessagesConversationsPath;
export const MessagesPostPath = { v2: '/messages/v2/post' };
export type MessagesPostVersion = keyof typeof MessagesPostPath;
export const MessagesAttachmentPath = { v2: '/messages/v2/attachment' };
export type MessagesAttachmentVersion = keyof typeof MessagesAttachmentPath;
export const AutoMeterReadCachePath = { v2: '/auto-meter-read/v2/cache' };
export type AutoMeterReadCacheVersion = keyof typeof AutoMeterReadCachePath;
export const AutoMeterReadCachePostPath = { v2: '/auto-meter-read/v2/cache' };
export type AutoMeterReadCachePostVersion = keyof typeof AutoMeterReadCachePostPath;
export const AutoMeterReadPath = { v2: '/auto-meter-read/v2' };
export type AutoMeterReadVersion = keyof typeof AutoMeterReadPath;
export const AutoMeterReadMetersPath = { v2: '/auto-meter-read/v2/meters' };
export type AutoMeterReadMetersVersion = keyof typeof AutoMeterReadMetersPath;
export const PaymentMethodsPath = { v2: '/payment-methods/v2' };
export type PaymentMethodsVersion = keyof typeof PaymentMethodsPath;
export const PaymentTypeUpdatePath = { v2: '/payment-type/v2/update' };
export type PaymentTypeUpdateVersion = keyof typeof PaymentTypeUpdatePath;
export const PaymentTypePath = { v2: '/payment-type/v2' };
export type PaymentTypeVersion = keyof typeof PaymentTypePath;
export const PaymentTypeSimulatePath = { v2: '/payment-type/v2/simulate' };
export type PaymentTypeSimulateVersion = keyof typeof PaymentTypeSimulatePath;
export const PaymentManageTransactionsRefundPath = {
  v2: '/payment-manage-transactions/v2/refund',
};
export type PaymentManageTransactionsRefundVersion = keyof typeof PaymentManageTransactionsRefundPath;
export const IdentifyPath = { v2: '/identify/v2' };
export type IdentifyVersion = keyof typeof IdentifyPath;
export const IdentifyEventPath = { v2: '/identify/v2/event' };
export type IdentifyEventVersion = keyof typeof IdentifyEventPath;
export const IdentifyRenderPath = { v2: '/identify/v2/render' };
export type IdentifyRenderVersion = keyof typeof IdentifyRenderPath;
export const GetCreateAccountCreditCheckPath = {
  v2: '/create-account/v2/credit-check',
};
export type GetCreateAccountCreditCheckVersion = keyof typeof GetCreateAccountCreditCheckPath;
export const GetCreateAccountBusinessPartnerPath = {
  v2: '/create-account/v2/business-partner',
};
export type GetCreateAccountBusinessPartnerVersion = keyof typeof GetCreateAccountBusinessPartnerPath;
export const CoBrowseTokenRefreshPath = { v2: '/co-browse/v2/token-refresh' };
export type CoBrowseTokenRefreshVersion = keyof typeof CoBrowseTokenRefreshPath;
export const AccountAccessPath = { v2: '/account-access/v2' };
export type AccountAccessVersion = keyof typeof AccountAccessPath;
export const SmoothPayEligibilityPath = { v2: '/smooth-pay-eligibility/v2' };
export type SmoothPayEligibilityVersion = keyof typeof SmoothPayEligibilityPath;
export const FourthTrimesterCreatePath = { v2: '/fourth-trimester/v2/create' };
export type FourthTrimesterCreateVersion = keyof typeof FourthTrimesterCreatePath;
export const FourthTrimesterSubmitPath = { v2: '/fourth-trimester/v2/submit' };
export type FourthTrimesterSubmitVersion = keyof typeof FourthTrimesterSubmitPath;
export const FourthTrimesterUpdatePath = { v2: '/fourth-trimester/v2/update' };
export type FourthTrimesterUpdateVersion = keyof typeof FourthTrimesterUpdatePath;
export const FourthTrimesterOfferEligibilityPath = {
  v2: '/fourth-trimester/v2/eligibility',
};
export type FourthTrimesterOfferEligibilityVersion = keyof typeof FourthTrimesterOfferEligibilityPath;
export const FourthTrimesterValidateLinkPath = {
  v2: '/fourth-trimester/v2/validate-link',
};
export type FourthTrimesterValidateLinkVersion = keyof typeof FourthTrimesterValidateLinkPath;
export const GetPanelPlansPath = { v2: '/panel-plans/v2' };
export type GetPanelPlansVersion = keyof typeof GetPanelPlansPath;
export const GetUserVoicePath = { v2: '/user-voice/v2' };
export type GetUserVoiceVersion = keyof typeof GetUserVoicePath;
export const BillEstimatePath = { v2: '/bill-estimates/v2/estimate' };
export type BillEstimateVersion = keyof typeof BillEstimatePath;
export const GetSubmitJourneyDataPath = {
  v2: (journeyType: string, ba: string, journeyId: string) =>
    `ivr/journey/v2/${journeyType}/customers/accounts/${ba}/journeys/${journeyId}`,
};
export type GetSubmitJourneyDataVersion = keyof typeof GetSubmitJourneyDataPath;
export const GetJourneyDataPath = {
  v2: (journeyType: string, journeyId: string) =>
    `ivr/journey/v2/${journeyType}/customers/accounts/journeys/${journeyId}`,
};
export const SubmitJourneyPath = { v2: '/submit-journey/v2' };
export type SubmitJourneyVersion = keyof typeof SubmitJourneyPath;
export const StatePath = { v2: '/state/v2' };
export type StateVersion = keyof typeof StatePath;
export const FourthTrimesterEmailsNewSendEmailPath = {
  v2: '/fourth-trimester-emails-new/v2/send-email',
};
export type FourthTrimesterEmailsNewSendEmailVersion = keyof typeof FourthTrimesterEmailsNewSendEmailPath;
export const McfuProfilePath = { v2: '/mcfu/v2/profile' };
export type McfuProfileVersion = keyof typeof McfuProfilePath;
export const McfuChangepasswordPath = { v2: '/mcfu/v2/changepassword' };
export type McfuChangepasswordVersion = keyof typeof McfuChangepasswordPath;
export const GetInteractionRecordsPath = {
  v2: '/customer-history/v2/interaction-records',
};
export type GetInteractionRecordsVersion = keyof typeof GetInteractionRecordsPath;
export const GetAttachmentPath = { v2: '/customer-history/v2/attachments' };
export type GetAttachmentVersion = keyof typeof GetAttachmentPath;
export const ConsumptionPath = { v2: '/consumption/v2' };
export type ConsumptionVersion = keyof typeof ConsumptionPath;
export const CsrLoginPath = { v2: '/csr-login/v2' };
export type CsrLoginVersion = keyof typeof CsrLoginPath;
export const PaymentsMobilePath = { v2: '/payments/v2/mobile' };
export type PaymentsMobileVersion = keyof typeof PaymentsMobilePath;
export const PaymentsPath = { v2: '/payments/v2' };
export type PaymentsVersion = keyof typeof PaymentsPath;
export const PaymentsA2aPath = { v2: '/payments/v2/a2a' };
export type PaymentsA2aVersion = keyof typeof PaymentsA2aPath;
export const ProductChangeEligibilityPath = {
  v2: '/product-change/v2/eligibility',
};
export type ProductChangeEligibilityVersion = keyof typeof ProductChangeEligibilityPath;
export const UsagePath = { v2: '/usage/v2' };
export type UsageVersion = keyof typeof UsagePath;
export const UsageWeeklyDifferencePath = { v2: '/usage/v2/weekly-difference' };
export type UsageWeeklyDifferenceVersion = keyof typeof UsageWeeklyDifferencePath;
export const UsagePlanDetailsPath = { v2: '/usage/v2/plan-details' };
export type UsagePlanDetailsVersion = keyof typeof UsagePlanDetailsPath;
export const ResetPasswordPath = { v2: '/password/v2/reset-password' };
export type ResetPasswordVersion = keyof typeof ResetPasswordPath;
export const ForgotPasswordPath = { v2: '/password/v2/forgotpassword' };
export type ForgotPasswordVersion = keyof typeof ForgotPasswordPath;
export const AccountActivationPath = { v2: '/password/v2/account-activation' };
export type AccountActivationVersion = keyof typeof AccountActivationPath;

// v1 & v2
export const LoginPath = { v2: '/login/v2', v1: '/login' };
export type LoginVersion = keyof typeof LoginPath;
