import * as actionTypes from "../../Actions/actionTypes";

const initialState = {
  BroadbandFullName: null,
  BroadbandId: null,
  Eligibilities: [],
  ApiPlan: null,
  Discounts: null,
  PriceChecker: { ElectricityICP: { IcpNumber: null } },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_READONLY:
      return { ...action.data };

    default:
      return state;
  }
};

export default reducer;
