/*eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Icon from "../Icon";
import Button from "../Button";
import ScrollContainer from "react-indiana-drag-scroll";
import { WhiteCheck, Close, ArrowRight } from "../../Assets/Icons";
import HTML from "../HTML";
import NavigationDots from "../NavigationDots";
import "./PlanCarousel.scss";
import { sitecoreVariables } from "../../../Config/Constants";
import { getCookie } from "../../../Utils/CookiesUtil";
import { capitalize } from "lodash";

const modalRoot = document.getElementById("modalRoot");

const PlanCarousel = (props) => {
  const [itemChecked, setItemChecked] = useState(null);
  const [isModalActive, setIsModalActive] = useState(false);
  const [isTCModalActive, setIsTCModalActive] = useState(false);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [dragScroll, setDragScroll] = useState(0);
  const [controlsScroll, setControllsScroll] = useState(false);
  const step = 350;
  const isBillCompareResult = props.enrichedIds.length > 0 ? true : false;
  const [modal, setModal] = useState({
    content: "",
    tCButtonText: "",
    choseButtonText: "",
  });

  const refs = useRef([]);
  const registerRef = useCallback((element) => refs.current.push(element), []);

  useEffect(() => {
    if (props.scrollToSelectedPlan) {
      refs.current[props.planIndex]?.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
    return () => props.resetScrollToSelectedPlan?.();
  });

  useEffect(() => {
    setItemChecked(props.planIndex);
  }, [props.planIndex]);

  const onItemSelect = (index) => {
    setIsModalActive(false);
    setItemChecked(itemChecked !== index ? index : null);
    if (itemChecked !== index) {
      props.onPress(props.data[index], index);
    } else {
      props.onPress({}, null);
    }
  };

  const returnPlanUI = (plan, field, url) => {
    return (
      <div>
        {plan.BestFor?.value && (
          <div className="planCarousel_container_block_label">
            <span>{plan.BestFor?.value}</span>
          </div>
        )}
        {plan.PlanRibbon.value && (
          <div className="planCarousel_container_block_ribbon-container">
            <div
              className={classNames(
                "planCarousel_container_block_ribbon-container_ribbon",
                "planCarousel_container_block_ribbon-container_ribbon--color"
              )}
            >
              <div className="planCarousel_container_block_ribbon-container_ribbon_text">
                {plan.PlanRibbon.value}
              </div>
            </div>
          </div>
        )}
        {plan.PlanRibbonBackground?.value && (
          <div className="planCarousel_container_block_ribbon-container">
            <div
              style={{ background: plan.PlanRibbonBackground.value }}
              className={classNames(
                "planCarousel_container_block_ribbon-container_ribbon--background",
                "planCarousel_container_block_ribbon-container_ribbon"
              )}
            />
          </div>
        )}
        {plan.AASFlogoImage?.value?.src && (
          <div className="planCarousel_container_block_logo-image">
            <img src={url} alt="" aria-hidden="true" />
          </div>
        )}
        <div className="planCarousel_container_block_name">
          {field.Title?.value}
        </div>
        <div className="planCarousel_container_block_description">
          <HTML html={plan.PlanPromo?.value} />
        </div>

        <div className="planCarousel_container_block_subHeader">
          {field.FirstRowSingleLineText?.value}
        </div>
      </div>
    );
  };

  const onModalShow = (plan, field, imageUrl, index) => {
    setIsModalActive(true);
    setModal({
      header: field.Title.value,
      content: field.DetailsDescription.value,
      card: returnPlanUI(plan, field, imageUrl),
      tcButtonText: field.DetailsTermsAndConditionsText?.value,
      buttonIndex: index,
      field: field,
      plan: plan,
      tcUrl: props.url + field.TandCPdfLink.value?.href,
    });
  };

  const renderSelectButton = (index, field, plan, className) => (
    <Button
      className={classNames(
        "planCarousel_container_block_buttons_button",
        className,
        itemChecked === index
          ? "planCarousel_container_block_buttons_button_buttonChecked"
          : ""
      )}
      handleClick={() => onItemSelect(index)}
      id={`choose-${field.Title?.value}-button`}
      text={
        itemChecked === index ? (
          <Icon
            icon={<WhiteCheck />}
            size={28}
            className="planCarousel_container_block_buttons_button_icon"
          />
        ) : (
          <div className="planCarousel_container_block_buttons_button_buttonText">
            {capitalize(plan.Label?.value)}
          </div>
        )
      }
    />
  );

  const scrollToElement = (params, isIndex) => {
    setControllsScroll(true);
    let index = 0;
    if (scrollIndex >= 0) {
      if (isIndex) {
        index = params;
      } else {
        index =
          scrollIndex +
          (params === "right"
            ? scrollIndex < props.data.length - 1
              ? 1
              : 0
            : scrollIndex > 0
            ? -1
            : 0);
      }
    }
    const name =
      props.data[index]?.fields?.ExistingPlan?.fields?.ApiPlanId.value;
    const element = document.getElementById(name);
    setScrollIndex(index);
    if (element) {
      // setTimout gives enough time for the element to be rendered
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }, 0);
      if (index === props.data.length - 1) {
        setScrollIndex(index);
      }
    }
  };

  const sharedPlanIdCookieName = sitecoreVariables.sharedPlanIdCookieName;
  const planIdCookie = getCookie(sharedPlanIdCookieName);

  useEffect(() => {
    if (props.planIndex > -1) {
      scrollToElement(props.planIndex, true);
    }
  }, [props.data, props.planIndex, planIdCookie]);

  const onScrollStart = (event) => {
    console.log("scroll started");
    setDragScroll(event);
  };

  const onScrollEnd = (event) => {
    if (!controlsScroll) {
      const scrollDifference = event - dragScroll;
      const scrollDitanse = Math.round(scrollDifference / step) * step;
      const numberOfSteps = Math.round(scrollDitanse / step);
      if (numberOfSteps >= 0) {
        setScrollIndex(scrollIndex + numberOfSteps);
      } else if (numberOfSteps < 0) {
        setScrollIndex(scrollIndex - Math.abs(numberOfSteps));
      }
      setControllsScroll(false);
    } else {
      setControllsScroll(false);
    }
  };

  //Render Enriched Cards for Bill Compare
  const renderEnrichedPlanCards = (list) => (
    <ScrollContainer
      className="planCarousel_container"
      onStartScroll={(event) => onScrollStart(event)}
      onEndScroll={(event) => onScrollEnd(event)}
    >
      {list.map((item, index) => {
        const plan = item.fields;
        const field = item.fields.ExistingPlan.fields;
        const imageUrl = props.url + plan?.AASFlogoImage?.value?.src;
        const isEnriched = props.enrichedIds?.find(
          (result) => result.planId === field.ApiPlanId?.value
        );

        return (
          <div className="planCarousel_billCompare" key={index}>
            <div
              className={
                isEnriched
                  ? "planCarousel_enrichedCards"
                  : "planCarousel_enrichedCards planCarousel_enrichedCards--disabled"
              }
            >
              You would've saved
              <span>&nbsp;${isEnriched ? isEnriched.amt.toFixed(2) : 0}</span>
              &nbsp; on your bill!
            </div>
            {getPlanCarousel(index, field, imageUrl, plan)}
          </div>
        );
      })}
    </ScrollContainer>
  );

  const getPlanCarousel = (index, field, imageUrl, plan) => {
    return (
      <div
        key={index}
        className="planCarousel_container_block"
        id={field.ApiPlanId?.value}
        data-productid={field.ApiPlanId?.value}
        ref={registerRef}
      >
        {returnPlanUI(plan, field, imageUrl)}
        <div className="planCarousel_container_block_buttons">
          {renderSelectButton(index, field, plan)}
          <Button
            id={`learn-${field.Title?.value}-button`}
            text={capitalize(field.FullPlanDetailsText?.value)}
            handleClick={() => onModalShow(plan, field, imageUrl, index)}
            className="planCarousel_container_block_buttons_button_link"
          />
        </div>
      </div>
    );
  };

  //Render Plan Cards for Customer Journey
  const renderListItem = (list) => (
    <ScrollContainer
      className="planCarousel_container"
      onStartScroll={(event) => onScrollStart(event)}
      onEndScroll={(event) => onScrollEnd(event)}
    >
      {list.map((item, index) => {
        const plan = item.fields;
        const field = item.fields.ExistingPlan.fields;
        const imageUrl = props.url + plan?.AASFlogoImage?.value?.src;
        return getPlanCarousel(index, field, imageUrl, plan);
      })}
    </ScrollContainer>
  );

  const scrollRight = () => {
    scrollToElement("right", false);
  };

  const scrollLeft = () => {
    scrollToElement("left", false);
  };

  const showArrows = props.data.length > 3;

  return (
    <div className="planCarousel" id="planCarousel">
      {isModalActive &&
        ReactDOM.createPortal(
          <div className="planCarousel_modal">
            <div className="planCarousel_modal_container">
              <div className="planCarousel_modal_container_mini-card">
                <div
                  className="planCarousel_modal_container_mini-card_close"
                  onClick={() => setIsModalActive(false)}
                >
                  <Icon
                    icon={<Close />}
                    className="planCarousel_modal_container_mini-card_close_icon"
                  />
                </div>
                <div className="planCarousel_modal_container_block--card">
                  {modal.card}
                </div>
              </div>
              <div className="planCarousel_modal_container_block">
                <div className="planCarousel_modal_container_block_header">
                  {modal.header}
                </div>
                <HTML html={modal.content} />
                <div className="planCarousel_modal_container_block_buttons">
                  <Button
                    id="TC"
                    text={modal.tcButtonText}
                    handleClick={() => setIsTCModalActive(true)}
                    className="planCarousel_modal_container_block_buttons_button-tc"
                    primaryBlack
                    customStyle={{
                      backgroundColor: props.constants.buttonBBColor,
                    }}
                  />
                  {renderSelectButton(
                    modal.buttonIndex,
                    modal.field,
                    modal.plan,
                    "planCarousel_modal_container_block_buttons_select"
                  )}
                </div>
              </div>
            </div>
          </div>,
          modalRoot
        )}
      {isTCModalActive &&
        ReactDOM.createPortal(
          <div className="planCarousel_modal">
            <div
              className={classNames(
                "planCarousel_modal_container",
                "planCarousel_modal_container--column"
              )}
            >
              <div
                className="planCarousel_modal_container_mini-card_close"
                onClick={() => setIsTCModalActive(false)}
              >
                <Icon
                  icon={<Close />}
                  className="planCarousel_modal_container_mini-card_close_icon"
                />
              </div>
              <div className="planCarousel_modal_container_title">
                {modal.field.TandCTitle.value}
              </div>
              <div>
                <HTML html={modal.field.TandCBodyText.value} />
              </div>
              <div className="planCarousel_modal_container_single-button">
                <a href={modal.tcUrl} target="_blank" rel="noopener noreferrer">
                  <div
                    className="planCarousel_modal_container_single-button_button-tc"
                    style={{ backgroundColor: props.constants.buttonBBColor }}
                  >
                    {modal.field.TandCPdfLink.value.text}
                  </div>
                </a>
              </div>
            </div>
          </div>,
          modalRoot
        )}
      <div className="planCarousel_listContainer">
        {showArrows && (
          <div
            className="planCarousel_arrow planCarousel_arrow--left"
            onClick={scrollLeft}
          >
            <Icon icon={<ArrowRight />} size={28} />
          </div>
        )}
        {isBillCompareResult
          ? renderEnrichedPlanCards(props.data)
          : renderListItem(props.data)}
        {showArrows && (
          <div
            className="planCarousel_arrow planCarousel_arrow--right"
            onClick={scrollRight}
          >
            <Icon icon={<ArrowRight />} size={28} />
          </div>
        )}
      </div>
      <NavigationDots
        elements={props.data}
        index={scrollIndex}
        onClick={scrollToElement}
      />
    </div>
  );
};

export default PlanCarousel;
