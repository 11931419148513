import React from "react";
import HTML from "../../HTML";
import "./styles.scss";

import NoRates from "./NoRates";
import DetailedRate from "./DetailedRate";
import Checkbox from "../../Checkbox";

import { WITH_GST } from "../constants";

const RatesContent = ({
  hasElectricityRatesError,
  messages,
  rates,
  gasOnly,
  elecUsageType,
  isBachPlan,
  onToggleUsageType,
  isNarrow,
  isIcon,
  isSummary,
  managedContent,
  gasRates,
  gasUsageType,
  onGasUsageToggle,
  hasGasRatesError,
  withGST,
  onToggleGST,
  hideWithGST,
}) => {
  return (
    <div className="ratesContent">
      <div className="ratesContent_rates">
        {hasElectricityRatesError && (
          <NoRates message={messages.noElectricityRates} />
        )}

        {rates &&
          !gasOnly &&
          rates.map((rate, key) => (
            <DetailedRate
              key={key}
              messages={messages}
              rate={rate}
              usageType={elecUsageType}
              areRatesToggleable={!isBachPlan}
              onToggleUsageType={onToggleUsageType}
              isNarrow={isNarrow}
              isIcon={isIcon}
              isSummary={isSummary}
              managedContent={managedContent}
              withGST={withGST}
            />
          ))}
        {gasRates &&
          gasRates.map((rate, index) => (
            <DetailedRate
              key={index}
              messages={messages}
              rate={rate}
              usageType={gasUsageType}
              areRatesToggleable={rate.lowRatesGSTExclusive?.length > 0}
              onToggleUsageType={onGasUsageToggle}
              isNarrow={isNarrow}
              isIcon={isIcon}
              isSummary={isSummary}
              managedContent={managedContent}
              withGST={withGST}
            />
          ))}
        {hasGasRatesError && <NoRates message={messages.noGasRates} />}
      </div>
      <br />
      {!hideWithGST && (
        <Checkbox
          name="withGst"
          label={WITH_GST}
          checked={withGST}
          onChange={onToggleGST}
        />
      )}

      <div className="ratesContent_legal">
        <HTML html={managedContent?.Disclaimer?.value} />
      </div>
    </div>
  );
};

export default RatesContent;
