import sha256 from "crypto-js/sha256";
import { Express as Constants } from "../Config/Constants";
import { Tracking, JOIN_JOURNEY_TYPE } from "../Config/Constants";
import { useCallback, useRef } from "react";
import isEqual from "lodash/isEqual";
import { Offering, Plan } from "@contact/data-access";
import {
  ModemPayTermsType,
  ModemSupplier,
} from "Redux/Hooks/CustomerJoinJourney";

const MOVE_EXPRESS = "Existing/MoveExpress-account";

const servicesFullName = {
  ELEC: "Electricity",
  GAS: "Piped Gas",
  LPG: "Bottled Gas",
  BB: "Broadband",
};

const loginTypes = {
  EXISTING: "online-account",
  NONE: "no-online-account",
  NEW: "new",
};

const sectionInfo = {
  AboutYou: { name: "about-you", step: "1" },
  Property: { name: "about-property", step: "2" },
  BottledGas: { name: "extras", step: "3" },
  BroadbandOrder: { name: "broadband", step: "4" },
  Payment: { name: "payment-setup", step: "5" },
  FinalStep: { name: "final-steps", step: "6" },
};

export const useTracking = (callback, dependencies, options = { limit: 1 }) => {
  const counter = useRef(0);
  const cb = useRef(callback);
  cb.current = callback;
  const deps = useRef(dependencies);
  const track = useCallback(() => {
    if (
      counter.current < options.limit &&
      !isEqual(deps.current, dependencies)
    ) {
      cb.current(...dependencies);
      counter.current++;
    }
  }, [counter, cb, deps, dependencies, options]);
  return track;
};

const getProducts = (apiPlan, services) => {
  return services && apiPlan
    ? services.map((service) => ({
        name: apiPlan.Name,
        id: apiPlan.PlanId,
        brand: apiPlan.Extras,
        variant: servicesFullName[service],
        category: "New/Join",
        quantity: 1,
        price: "",
      }))
    : [];
};

const getExpressProducts = () => [
  {
    name: Constants.planInfo.planName,
    id: Constants.planInfo.planId,
    price: "",
    brand: "No fixed term",
    category: MOVE_EXPRESS,
    variant: "Electricity",
    quantity: 1,
  },
];

const getEcommerceItem = (product, position?: number, variant?: string) => {
  const item = {
    item_name: product.Name,
    item_id: product.Id,
    ...(variant && { item_variant: variant }), // e.g. Tracking.ELEC
    item_brand: product.FixedTerm ? Tracking.fixedTerm : Tracking.noFixedTerm,
    item_category: Tracking.joinJourney.item_category,
    item_category2: Tracking.joinJourney.item_category2,
    item_list_name: "planCarousel",
    item_list_id: "planCarousel",
    ...(position && { index: position }), //the position of the card within the carousel - starting from ‘1’
    quantity: 1,
  };

  return item;
};

const getEcommerce = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan: Plan,
  bbPlan?: Offering,
  planPosition?: number
) => {
  if (!plan) {
    return null;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const items: any[] = [];

  if (!isLPGOnly && isLPGInServiceList) {
    // Add dummy LPG Plan variant for tracking
    const lpgItem = getEcommerceItem(plan, planPosition, Tracking.LPG);
    items.push(lpgItem);
  }

  const planVariant =
    plan.Id === Tracking.LPG_Id ? Tracking.LPG : Tracking.ELEC;

  const item = getEcommerceItem(plan, planPosition, planVariant);
  items.push(item);

  let bbItem;

  if (bbPlan) {
    const bbVariant = Tracking.BB + " - " + bbPlan.Name;
    bbItem = getEcommerceItem(plan, planPosition, bbVariant); // should still use the selected plan here but with variant info
    items.push(bbItem);
  }

  return {
    ecommerce: { items },
  };
};

const getEcommerceByPlans = (plans) => {
  if (!plans) {
    return null;
  }

  const items = plans.map((plan, index) => getEcommerceItem(plan, index + 1));

  return {
    ecommerce: { items },
  };
};

const getAddress = (address) => {
  if (address) {
    const lastIndex = address.lastIndexOf(", ");
    const streetSuburb = address.substring(0, lastIndex);
    const [city, zip] = address.substring(lastIndex + 2).split(" ");
    return { streetSuburb, city, zip };
  }
  return {};
};

const hashString = (message) => {
  return message ? sha256(message.toLowerCase()).toString() : null;
};

// Call the following dataLayer when the referrer is NOT contact.co.nz
// and before the GTM container code
export const triggerOriginalLandingPage = () => {
  const referrer = document.referrer;

  // When referrer contains contact.co.nz, then do nothing
  if (referrer.indexOf("contact.co.nz") !== -1) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    originalLocation:
      document.location.protocol +
      "//" +
      document.location.hostname +
      document.location.pathname +
      document.location.search,
  });
};

export const triggerMyDetailsJourney = (JourneyType) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "MyDetailsJourney",
    journey:
      JourneyType === JOIN_JOURNEY_TYPE
        ? "New/Join"
        : `Existing/${JourneyType ?? ""}`,
  });
};

export const triggerCheckoutStep = (apiPlan, services, section) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "checkoutStep",
    virtualUrl: "/residential/my-details/" + sectionInfo[section].name,
    ecommerce: {
      checkout: {
        products: getProducts(apiPlan, services),
        actionField: { step: sectionInfo[section].step },
      },
    },
  });
};

export const triggerBillCompareAnalytics = (
  isBillCompareFeatureOn,
  isBillCompareABTestingOn
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "feature_flag",
    component: "bill compare widget", // the name of the feature
    status: isBillCompareFeatureOn ? "on" : "off", // or "off" if the feature has been turned off in CMS
    ab_testing_group: isBillCompareABTestingOn
      ? "bill compare widget on"
      : "bill compare widget off", // or "bill compare widget off" if status is ‘off’
  });
};

export const triggerPurchased = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "purchased",
  });
};

export const triggerPurchase = (apiPlan, services, icp, promoCode) => {
  if (process.env.NODE_ENV !== "development") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        purchase: {
          products: getProducts(apiPlan, services),
          transaction_id: "",
          affiliation: "web",
          coupon: promoCode,
        },
      },
    });
  }
};

export const triggerMyDetailsPageview = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "My-Details-Pageview",
    virtualUrl: "/residential/my-details/join-success",
  });
};

export const triggerNPSSubmit = (rate, text) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "NpsQuestionnaireSubmit",
    rate: rate,
    text: text,
  });
};

export const triggerAsyncNPSSubmit = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "NpsQuestionnaireSubmit",
    rate: data.score,
    text: data.scoreComments,
    preferredChannel: data.preferredChannel,
    useAsyncAgain: data.useAsyncAgain,
    async_expected_resolution_time: data.howLongToWaitItems.join("|"),
  });
};

export const triggerCustomerInfo = (
  email,
  firstName,
  lastName,
  phone,
  optin = false
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "customerInfo",
    hashedEmail: hashString(email),
    hashedFN: hashString(firstName),
    hashedLN: hashString(lastName),
    hashedPnum: hashString(phone),
    optIn: optin ? "opted-in" : "opted-out",
  });
};

export const triggerPropertyInfo = (propertyAddress) => {
  const address = getAddress(propertyAddress);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "propertyInfo",
    hashedAddress: hashString(address.streetSuburb),
    hashedCity: hashString(address.city),
    hashedZip: hashString(address.zip),
  });
};

export const triggerPageView = (type) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "MoveExpress-Pageview",
    virtualUrl: "/residential/express-move/" + loginTypes[type],
  });
};

export const triggerSuccessLogin = (accountId, businessPartnerId) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "loginEvent",
    login: "successfulLogin",
    baid: accountId,
    bpid: businessPartnerId,
  });
};

export const triggerGuestCheckout = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "guestCheckout",
  });
};

export const triggerClickToRegister = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "clickToRegister",
  });
};

export const triggerClicktostartNew = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "clicktostartNew",
  });
};

export const triggerMoveExpressJourney = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "MoveExpressJourney",
    journey: MOVE_EXPRESS,
  });
};

export const triggerCheckoutStepExpressJourney = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "checkoutStep",
    ecommerce: {
      checkout: {
        actionField: { step: "1" },
        products: getExpressProducts(),
      },
    },
  });
};

export const triggerExpressMoveSubmitted = (
  vulnerability,
  medicalDependence,
  hazards
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "expressMoveSubmitted",
    vulnerability: vulnerability ? "vulnerable" : "non-vulnerable",
    medicalDependence: medicalDependence ? "dependent" : "non-dependent",
    hazards: hazards.join("|"),
  });
};

export const triggerPurchaseExpress = (icp, promoCode) => {
  if (process.env.NODE_ENV !== "development") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        purchase: {
          transaction_id: "",
          affiliation: "web",
          coupon: promoCode,
          products: getExpressProducts(),
        },
      },
    });
  }
};

export const triggerDriverLicense = (data) => {
  const dlEmptyOrValid = {
    event: "IdChecked",
    status: data?.verified ? Tracking.pass : Tracking.noUse,
  };
  const dlNotValid = {
    event: "IdChecked",
    status: Tracking.fail,
    errorMsg: data?.invalidFields?.join("|"),
  };
  const dlData = data?.verified || !data ? dlEmptyOrValid : dlNotValid;
  dataLayerEvent(dlData);
};

// deprecated: please use triggerDriverLicense
export const triggerIdCheck = (status, failedFields?) => {
  window.dataLayer = window.dataLayer || [];
  if (failedFields) {
    window.dataLayer.push({
      event: "IdChecked",
      status,
      errorMsg: failedFields.join("|"), //form field name (id) separated by a pipe
    });
  } else {
    window.dataLayer.push({
      event: "IdChecked",
      status,
    });
  }
};

export const triggerSummaryJourney = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "SummaryJourney",
    journey: "New/Not Selected",
  });
};

export const triggerEPValues = (icp, available, region) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "EPValues",
    available,
    icp,
    pricingCalc: "Pricing Calculator",
    provider: "",
    region,
  });
};

export const triggerProductImpressions = (products) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "productImpression",
    ecommerce: {
      impressions: products.map((product, index) => ({
        brand: "",
        category: "New/Not Selected",
        id: product.Id,
        list:
          "contentpage standard-template basepage residential font-trial ijoin",
        name: product.Name,
        position: index + 1,
        price: "",
        variant: "",
      })),
    },
  });
};

export const triggerProductClick = (product, position) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "productClick",
    ecommerce: {
      click: {
        actionField: {
          list:
            "contentpage standard-template basepage residential font-trial ijoin",
        },
        products: [
          {
            name: product.Name,
            id: product.Id,
            price: "",
            brand: "",
            category: "New/Join",
            variant: "",
            position,
          },
        ],
      },
    },
  });
  window.dataLayer.push({
    event: "detailView",
    ecommerce: {
      detail: {
        products: [
          {
            name: product.Name,
            id: product.Id,
            price: "",
            brand: "",
            category: "New/Join",
            variant: "",
          },
        ],
      },
    },
  });
};

export const triggerPlanBuilderPageSubmitted = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "planBuilderPageSubmitted",
    baid: undefined,
    journey: "New/Join",
  });
};

export const triggerJoinPromoCodeSubmitted = (promoCode, isValid, message) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "promoCodesubmitted",
    promoCode: promoCode,
    promoCodeStatus: isValid ? "valid" : "invalid",
    promoCodeApplicationStatus: message,
    journey: "New/Not Selected",
  });
};

export const triggerSummaryPageSubmitted = (email, propertyAddress) => {
  const address = getAddress(propertyAddress);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "summaryPageSubmitted",
    optIn: undefined,
    hashedEmail: hashString(email),
    hashedAddress: hashString(address.streetSuburb),
    hashedCity: hashString(address.city),
    hashedZip: hashString(address.zip),
    journey: "New/Not Selected",
  });
};

export const triggerServiceToggle = (isOpen, service) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: isOpen ? "show_service_details" : "hide_service_details",
    service: service,
  });
};

export const triggerServiceSelect = (service) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "select_service",
    service: Tracking[service],
  });
};

export const triggerServiceEnter = (serviceType, icp, data) => {
  window.dataLayer = window.dataLayer || [];
  const electricity = {
    event: "service_result",
    service: serviceType,
    icp,
    status: data.status,
    meter_type: data.meterType ? data.meterType : null,
    provider: data.trader,
    switch_in_progress: data.switchInProgress,
    region: data.regionCode, // "E028"
  };
  const pipedGas = {
    event: "service_result",
    service: serviceType,
    icp,
    status: data.statusDescription,
  };
  const broadband = {
    event: "service_result",
    service: serviceType,
    status: data.status || "unknown",
    provider: data.trader || "unknown",
    address: data.Address,
    fibre: data.fibre || "unknown",
  };
  const currentService =
    serviceType === "electricity"
      ? electricity
      : serviceType === "piped gas"
      ? pipedGas
      : broadband;

  window.dataLayer.push(currentService);
};

export const triggerPlanListView = (plans) => {
  dataLayerEvent({ ecommerce: null }); // Clear the previous ecommerce object.

  const ecommerce = getEcommerceByPlans(plans);

  const eventObj = {
    event: "view_item_list",
    ...ecommerce,
  };

  dataLayerEvent(eventObj);
};

export const triggerPlanSelect = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan,
  position?: number
) => {
  dataLayerEvent({ ecommerce: null }); // Clear the previous ecommerce object.

  const ecommerce = getEcommerce(
    isLPGOnly,
    isLPGInServiceList,
    plan,
    undefined,
    position
  );

  const eventObj = {
    event: "select_item",
    ...ecommerce,
  };

  dataLayerEvent(eventObj);
};

export const triggerPlanView = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan,
  position?: number
) => {
  dataLayerEvent({ ecommerce: null }); // Clear the previous ecommerce object.

  const ecommerce = getEcommerce(
    isLPGOnly,
    isLPGInServiceList,
    plan,
    undefined,
    position
  );

  const eventObj = {
    event: "view_item",
    ...ecommerce,
  };

  dataLayerEvent(eventObj);
};

// Francois: For variant selection, we use `view_item` instead of `select_item` since we only use `select_item` for first level product
export const triggerBBPlanView = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  elecPlan,
  elecPlanPosition,
  bbPlan
) => {
  dataLayerEvent({ ecommerce: null }); // Clear the previous ecommerce object.

  const ecommerce = getEcommerce(
    isLPGOnly,
    isLPGInServiceList,
    elecPlan,
    bbPlan,
    elecPlanPosition
  );

  const eventObj = {
    event: "view_item",
    ...ecommerce,
  };

  dataLayerEvent(eventObj);
};

export const triggerPromoCodeSubmitted = (data, promoCode) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "promoCodesubmitted",
    promoCode: promoCode,
    promoCodeStatus: data.IsValid ? Tracking.valid : Tracking.invalid,
    promoCodeApplicationStatus: data.Description,
  });
};

export const triggerAddToCart = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan?: Plan,
  bbPlan?: Offering,
  addressDetails?
) => {
  // No plan to track, BG only for example
  if (!plan) return;

  dataLayerEvent({ ecommerce: null }); // Clear the previous ecommerce object.

  const ecommerce = getEcommerce(isLPGOnly, isLPGInServiceList, plan, bbPlan);

  const eventObj = {
    event: "add_to_cart",
    journey: Tracking.joinJourney.name,
    hashedAddress: hashString(addressDetails.address_line_1),
    hashedCity: hashString(addressDetails.city),
    hashedZip: hashString(addressDetails.postcode),
    ...ecommerce,
  };

  dataLayerEvent(eventObj);
};

export const triggerBeginCheckout = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan?: Plan,
  bbPlan?: Offering
) => {
  // No plan to track, BG only for example
  if (!plan) return;

  dataLayerEvent({ ecommerce: null }); // Clear the previous ecommerce object.

  const ecommerce = getEcommerce(isLPGOnly, isLPGInServiceList, plan, bbPlan);

  const eventObj = {
    event: "begin_checkout",
    ...ecommerce,
  };

  dataLayerEvent(eventObj);
};

export const triggerBPCreate = (bp) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "sign_up",
    bpid: bp,
  });
};

export const triggerBPUpdated = (bp) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "update_bp",
    bpid: bp,
  });
};

export const triggerAccountUpdate = (bp) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "account_updated",
    bpid: bp,
  });
};

export const triggerCCResult = (result) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "credit_check_result",
    result: result,
  });
};

export const triggerPersonAdded = (type) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event:
      type === "JAH" ? "joint_account_holder_added" : "authorised_person_added",
  });
};

export const dataLayerEvent = (event) => {
  if (!window.dataLayer) {
    //eslint-disable-next-line no-console
    console.warn("data layer not installed");
  }

  window.dataLayer = window.dataLayer || [];

  if (event) {
    window.dataLayer.push(event);
  }
};

export const triggerTooltip = ({ type, info_text }) => {
  dataLayerEvent({
    event: "info_click",
    type,
    info_text,
  });
};

export const triggerCheckBox = ({ event, checkbox_name }) => {
  dataLayerEvent({
    event,
    checkbox_name,
  });
};

export const triggerConfirmPipedGasICPModalShownUp = () => {
  dataLayerEvent({
    event: "virtualPageview",
    virtualUrl: window.location.pathname + "/confirm-piped-gas-icp-modal",
    virtualPageTitle: "VPV-Confirm your piped gas ICP",
    service: "piped gas",
  });
};

export const triggerConfirmPipedGasICPModalManuallyShownUp = () => {
  dataLayerEvent({
    event: "virtualPageview",
    virtualUrl:
      window.location.pathname + "/confirm-piped-gas-icp-modal-manually",
    virtualPageTitle: "VPV-Confirm your piped gas ICP Manually",
    service: "piped gas",
  });
};

export const triggerConfirmButton = () => {
  dataLayerEvent({
    event: "cta_click",
    cta_text: "confirm",
  });
};

export const triggerCancelButton = () => {
  dataLayerEvent({
    event: "cta_click",
    cta_text: "cancel",
  });
};

export const triggerError = () => {
  dataLayerEvent({
    event: "error",
  });
};

export const triggerConfirmPipedGasICPModal_ConfirmSuccessfully = (
  icp: string,
  statusDescription: string
) => {
  dataLayerEvent({
    event: "icp_confirmed",
    icp,
    status: statusDescription,
  });
};

export const triggerMedicalDependencyWhenEnter = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan,
  bbPlan
) => {
  const ecommerce = getEcommerce(isLPGOnly, isLPGInServiceList, plan, bbPlan);

  dataLayerEvent({
    event: "checkoutStep",
    step: "2",
    virtualUrl: "/residential/my-details/medical-dependency",
    ...ecommerce,
  });
};

export const triggerMedicalDependencyWhenCompleted = (
  hasVulnerablePerson,
  hasMedicalDependant
) => {
  dataLayerEvent({
    event: "step_completed",
    vulnerability: hasVulnerablePerson ? "vulnerable" : "non-vulnerable",
    medicalDependence: hasMedicalDependant ? "dependent" : "non-dependent",
  });
};

export const triggerPropertySectionWhenEnter = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan,
  bbPlan
) => {
  const ecommerce = getEcommerce(isLPGOnly, isLPGInServiceList, plan, bbPlan);

  dataLayerEvent({
    event: "checkoutStep",
    step: "3",
    virtualUrl: "/residential/my-details/about-property",
    ...ecommerce,
  });
};

export const triggerPropertySectionWhenCompleted = (hazards: string[] = []) => {
  dataLayerEvent({
    event: "step_completed",
    hazards: hazards.join("|"),
  });
};

export const triggerBottledGasSectionWhenEnter = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan,
  bbPlan
) => {
  const ecommerce = getEcommerce(isLPGOnly, isLPGInServiceList, plan, bbPlan);

  dataLayerEvent({
    event: "checkoutStep",
    step: "4",
    virtualUrl: "/residential/my-details/about-your-bottled-gas-order",
    ...ecommerce,
  });
};

export const triggerBottledGasSectionWhenCompleted = (
  NumberOfBottles: string,
  PaperlessStatements: boolean
) => {
  dataLayerEvent({
    event: "step_completed",
    bottled_gas_num: NumberOfBottles, // or ‘0’, ‘3’, ‘4’, ‘5+’
    emailed_bottled_gas_bill: PaperlessStatements ? "opted-in" : "opted-out",
  });
};

export const triggerBroadbandSectionWhenEnter = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan,
  bbPlan
) => {
  const ecommerce = getEcommerce(isLPGOnly, isLPGInServiceList, plan, bbPlan);

  dataLayerEvent({
    event: "checkoutStep",
    step: "5",
    virtualUrl: "/residential/my-details/about-your-broadband-order",
    ...ecommerce,
  });
};

export const triggerBBFeatureFlag = (status) => {
  dataLayerEvent({
    event: "feature_flag",
    component: "new modem option",
    status: status,
  });
};

export const triggerBroadbandSectionWhenCompleted = ({
  broadbandProvider,
  addPhoneLine,
  phoneLineOptions,
}: {
  broadbandProvider: string;
  addPhoneLine: boolean;
  phoneLineOptions: { checked: boolean; code: string }[];
}) => {
  const phoneLines = phoneLineOptions
    .filter((opt) => opt.checked)
    .map((opt) => opt.code)
    .join("|");
  dataLayerEvent({
    event: "step_completed",
    provider: broadbandProvider ? broadbandProvider : "none",
    landline: addPhoneLine ? "yes" : "no",
    landline_addons: phoneLines ? phoneLines : "none",
  });
};

export const triggerModemSectionWhenEnter = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan,
  bbPlan
) => {
  const ecommerce = getEcommerce(isLPGOnly, isLPGInServiceList, plan, bbPlan);

  dataLayerEvent({
    event: "checkoutStep",
    step: "8",
    virtualUrl: "/residential/my-details/about-your-modem",
    ...ecommerce,
  });
};

export const triggerModemSectionWhenCompleted = ({
  modemDeliveredToSameAddress,
  modemSupplier,
  modemPayTerms,
}: {
  modemDeliveredToSameAddress: boolean;
  modemSupplier?: ModemSupplier;
  modemPayTerms?: ModemPayTermsType;
}) => {
  dataLayerEvent({
    event: "step_completed",
    modem_delivery: modemDeliveredToSameAddress
      ? "same address"
      : "different address",
    modem_option: modemSupplier,
    modem_payterm: modemPayTerms,
  });
};

export const triggerPaymentSetupSectionWhenEnter = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan,
  bbPlan
) => {
  const ecommerce = getEcommerce(isLPGOnly, isLPGInServiceList, plan, bbPlan);

  dataLayerEvent({
    event: "checkoutStep",
    step: "6",
    virtualUrl: "/residential/my-details/payment-setup",
    ...ecommerce,
  });
};

export const triggerDirectDebitSetup = () => {
  dataLayerEvent({
    event: "direct_debit_setup",
  });
};

export const triggerFinalStepSectionWhenEnter = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  plan,
  bbPlan
) => {
  const ecommerce = getEcommerce(isLPGOnly, isLPGInServiceList, plan, bbPlan);

  dataLayerEvent({
    event: "checkoutStep",
    step: "7",
    virtualUrl: "/residential/my-details/final-step",
    ...ecommerce,
  });
};

export const triggerConfirmationPage = (
  isLPGOnly: boolean,
  isLPGInServiceList: boolean,
  promoCode,
  transactionId,
  plan,
  bbPlan
) => {
  const ecom = getEcommerce(isLPGOnly, isLPGInServiceList, plan, bbPlan);

  dataLayerEvent({
    event: "purchase",
    ecommerce: {
      transaction_id: transactionId,
      affiliation: "web",
      coupon: promoCode,
      items: ecom?.ecommerce?.items,
    },
  });
};

export const triggerTopPlansABTestAnalytics = (
  topPlansABTestOn?: boolean,
  topPlansABTestIgnored?: boolean
) => {
  dataLayerEvent({
    event: "feature_flag",
    component: "find a plan top plans",
    status: "on",
    ab_testing_group: `find a plan top plans ${
      topPlansABTestIgnored ? "ignore" : topPlansABTestOn ? "on" : "off"
    }`,
  });
};
