import * as actionTypes from "../../Actions/actionTypes";
import { reducerPropUpdater } from "../../utils";
import { Payment as Constants } from "../../../Config/Constants";
import { PAYMENT_CYCLE_MONTHLY } from "../../../Config/TypedConstants";
import { smoothPayState } from "./SmoothPay";

export const initialState = {
  PaymentMethod: Constants.paymentMethodDefault,
  PrePayMobileNumber: undefined,
  PaperlessDiscount: Constants.paperlessDiscountDefault,
  JoinDirectDebit: false,
  DirectDebitDetails: {
    BankName: undefined,
    BankAccountNumber: undefined,
    IsSoleSignatory: true,
    AgreeWithTermsAndConditions: false,
  },
  BillingCycle: Constants.billingCycleDefault,
  BillStartDate: undefined,
  PromoCode: "",
  SmoothPaySetUp: false,
  SmoothPayFrequency: PAYMENT_CYCLE_MONTHLY,
};

const updateProp = reducerPropUpdater("Payment");

const updatePayment = (state, payment) => {
  return {
    ...state,
    ...payment,
    DirectDebitDetails: {
      ...state.DirectDebitDetails,
      ...payment.DirectDebitDetails,
    },
  };
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROP:
      return updateProp(state, action.propName, action.value);

    case actionTypes.UPDATE_PAYMENT:
      return updatePayment(state, action.payment);

    default:
      return state;
  }
};

export const joinReducer = (state = initialState, action) => {
  const nextState = reducer(state, action);
  return smoothPayState(nextState, state);
};

export default joinReducer;
