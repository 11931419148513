/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { compose, branch, renderComponent } from "recompose";
import classNames from "classnames";
import ExpansionPanel from "../ExpansionPanel";

import "./Rates.scss";

import {
  USAGE_TYPE_LOW,
  USAGE_TYPE_STANDARD,
  USER_TYPE_LOW,
  USER_TYPE_STANDARD,
} from "./constants";
import {
  areRatesValid,
  hasElectricityRatesError,
  hasGasRatesError,
} from "./utils";

import ToggleButton from "../ToggleButton";
import Error from "./Error";
import RatesContent from "./RatesContent";

const Rates = ({
  id,
  rates,
  isBachPlan,
  messages,
  className,
  isOpenByDefault,
  isNarrow,
  isSummary,
  setIsStandard,
  isIcon,
  gasRate,
  gasOnly,
  managedContent,
  gasUsageType,
  onGasUsageToggle,
  setElecUserType,
  elecUserType,
  withGST,
  onToggleGST,
  hideWithGST,
  isElectricityInServiceList,
  isGasInServiceList,
}) => {
  const [isExpanded, setIsExpanded] = useState(isOpenByDefault);
  const elecUsageType =
    elecUserType === USER_TYPE_LOW ? USAGE_TYPE_LOW : USAGE_TYPE_STANDARD;
  const gasUsageTypeShorthand =
    gasUsageType?.split(" - ")[0] === USER_TYPE_LOW
      ? USAGE_TYPE_LOW
      : USAGE_TYPE_STANDARD;

  useEffect(() => {
    if (setIsStandard) {
      setIsStandard(elecUsageType === USAGE_TYPE_STANDARD);
    }
  }, [elecUsageType, setIsStandard]);

  const handleToggleUsageType = useCallback(() => {
    setElecUserType(
      elecUsageType === USAGE_TYPE_STANDARD ? USER_TYPE_LOW : USER_TYPE_STANDARD
    );
  }, [elecUsageType, setElecUserType]);

  return (
    <div id={id} className={classNames(className, "rates")}>
      <div
        className={classNames(
          "rates_top-row",
          isSummary ? "rates_top-row--summary" : ""
        )}
      >
        <div className="rates_top-row_column">
          <ToggleButton
            onToggle={() => setIsExpanded(!isExpanded)}
            expanded={isExpanded}
            expandLabel={messages.expand}
            collapseLabel={messages.collapse}
            className="rates_toggleRates"
          />
        </div>
      </div>
      <ExpansionPanel isExpanded={isExpanded}>
        <RatesContent
          messages={messages}
          rates={rates}
          gasRates={gasRate}
          elecUsageType={elecUsageType}
          gasUsageType={gasUsageTypeShorthand}
          onToggleUsageType={handleToggleUsageType}
          onGasUsageToggle={onGasUsageToggle}
          hasElectricityRatesError={
            isElectricityInServiceList &&
            hasElectricityRatesError(rates, elecUsageType)
          }
          hasGasRatesError={
            isGasInServiceList &&
            hasGasRatesError(gasRate, gasUsageTypeShorthand)
          }
          isBachPlan={isBachPlan}
          isNarrow={isNarrow}
          isSummary={isSummary}
          isIcon={isIcon}
          gasOnly={gasOnly}
          managedContent={managedContent}
          withGST={withGST}
          onToggleGST={onToggleGST}
          hideWithGST={hideWithGST}
        />
      </ExpansionPanel>
    </div>
  );
};

export default compose(
  branch(
    ({ rates, gasRate, isGasInServiceList }) =>
      !isGasInServiceList
        ? !areRatesValid(rates)
        : !areRatesValid(rates) && !gasRate,
    renderComponent(Error)
  )
)(Rates);
