import React, { useCallback, useMemo, useEffect } from "react";
import classNames from "classnames";

import "./Checkbox.scss";
import Icon from "../Icon";
import { Check, Help, Uncheck, UncheckError } from "../../Assets/Icons";
import noop from "nop";

const Checkbox = ({
  checked = false,
  validateItself = false,
  required = false,
  iconClicked = undefined,
  disabled = false,
  onChange,
  name = undefined,
  className = undefined,
  hasHelpIcon = false,
  label,
  subLabel = undefined,
  handleError = noop,
  hasError = false,
  inputProps = undefined,
}) => {
  const icon = useMemo(
    () =>
      checked ? (
        <Check />
      ) : validateItself && required ? (
        <UncheckError />
      ) : (
        <Uncheck />
      ),
    [checked, validateItself, required]
  );

  // Note: this is used as a ref, it will always have unstable references to its scope, so useCallback is not needed here.
  const getInputRef = (input) => {
    if (inputProps && inputProps.ref) {
      if (typeof inputProps.ref === "object") {
        inputProps.ref.current = input;
      } else if (typeof inputProps.ref === "function") {
        inputProps.ref(input);
      }
    }
  };

  useEffect(() => {
    if (handleError && validateItself && !hasError && required && !checked) {
      handleError(true);
    }
  }, [checked, handleError, hasError, required, validateItself]);

  useEffect(() => {
    if (handleError && hasError && checked) {
      handleError(false);
    }
  }, [checked, handleError, hasError]);

  const helpIconClick = useCallback(
    (e) => {
      if (e) {
        e.stopPropagation();
      }
      if (iconClicked) {
        iconClicked();
      }
    },
    [iconClicked]
  );

  const onKeyDown = useCallback(
    (e) => {
      if (disabled) return;
      if (e.key === " ") {
        onChange(e);
        e.preventDefault();
      }
    },
    [onChange, disabled]
  );

  const onClick = useCallback(
    (e) => {
      if (disabled) return;
      onChange(e);
    },
    [onChange, disabled]
  );

  return (
    <div
      ref={getInputRef}
      id={name}
      name={name}
      className={classNames("checkbox", className, {
        disabled,
      })}
      onClick={onClick}
      role="checkbox"
      aria-checked={checked}
    >
      <Icon
        className={
          checked ? "checkbox_box checkbox_box--checked" : "checkbox_box"
        }
        icon={icon}
        tabindex="0"
        onKeyDown={onKeyDown}
      />
      <div className="checkbox_container">
        <div name={name ? name + "Label" : null} className="checkbox_label">
          {label}
        </div>
        {subLabel ? (
          <div
            name={name ? name + "SubLabel" : null}
            className="checkbox_sublabel"
          >
            {subLabel}
          </div>
        ) : undefined}
      </div>
      {hasHelpIcon && (
        <Icon
          name="helpIcon"
          onClick={helpIconClick}
          className="checkbox_box checkbox_icon"
          icon={<Help />}
        />
      )}
    </div>
  );
};

export default Checkbox;
