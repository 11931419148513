import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as Help } from "./help.svg";
import { ReactComponent as DownloadBill } from "./downloadBill.svg";
import { ReactComponent as PropertySwitcher } from "./propertySwitcher.svg";
import { ReactComponent as Uncheck } from "./uncheck.svg";
import { ReactComponent as UncheckError } from "./uncheckError.svg";
import { ReactComponent as Menu } from "./menu.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as Error } from "./error.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as Block } from "./block.svg";
import { ReactComponent as ArrowRight } from "./arrowRight.svg";
import { ReactComponent as ArrowDown } from "./arrowDown.svg";
import { ReactComponent as ArrowLeftRight } from "./arrow-left-right.svg";
import { ReactComponent as LongArrowUp } from "./longArrowUp.svg";
import { ReactComponent as LongArrowDown } from "./longArrowDown.svg";
import { ReactComponent as WhiteCheck } from "./whiteCheck.svg";
import { ReactComponent as SelectedTick } from "./selectedTick.svg";
import { ReactComponent as Primary } from "./primary.svg";
import { ReactComponent as Primaryb } from "./primaryb.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as Electricity } from "./electricity.svg";
import { ReactComponent as Gas } from "./gas.svg";
import { ReactComponent as InProgress } from "./inProgress.svg";
import { ReactComponent as Resolved } from "./resolved.svg";
import { ReactComponent as WelcomePack } from "./welcomePack.svg";

import { ReactComponent as AsbLogo } from "./AsbLogo.svg";
import { ReactComponent as AnzLogo } from "./AnzLogo.svg";
import { ReactComponent as GenericCardLogo } from "./GenericCardLogo.svg";
import { ReactComponent as BnzLogo } from "./BnzLogo.svg";
import { ReactComponent as CooperativeLogo } from "./CooperativeLogo.svg";
import { ReactComponent as KiwiBankLogo } from "./KiwiBankLogo.svg";
import { ReactComponent as SbsLogo } from "./SbsLogo.svg";
import { ReactComponent as TsbLogo } from "./TsbLogo.svg";
import { ReactComponent as WestpacLogo } from "./WestpacLogo.svg";
import { ReactComponent as HsbcLogo } from "./HsbcLogo.svg";
import { ReactComponent as SwitchSvg } from "./switchSvg.svg";

import { ReactComponent as AppBank } from "./bank.svg";
import { ReactComponent as AppBottledGas } from "./bottledGas.svg";
import { ReactComponent as BottledGas } from "./bottle-gas-bold.svg";
import { ReactComponent as AppBroadbandClock } from "./broadband-clock.svg";
import { ReactComponent as AppBroadbandHome } from "./broadband-home.svg";
import { ReactComponent as BroadbandHomeBold } from "./broadband-home-bold.svg";
import { ReactComponent as BroadBandLogo } from "./newBroadBandLogo.svg";
import { ReactComponent as ElectricityLogo } from "./newElectricityLogo.svg";
import { ReactComponent as BottleGasLogo } from "./bottleGasLogo.svg";
import { ReactComponent as PipedGasLogo } from "./newPipedGasLogo.svg";
import { ReactComponent as AppCalendar } from "./app-calendar.svg";
import { ReactComponent as AppElectricity } from "./electricity-thin.svg";
import { ReactComponent as AppEnvelope } from "./envelope.svg";
import { ReactComponent as BroadBandCircle } from "./broadband-circle-icon.svg";
import { ReactComponent as ServiceEnvelope } from "./email-circle-icon.svg";
import { ReactComponent as ServiceCircle } from "./services-circle-icon.svg";
import { ReactComponent as AppHotMugCircle } from "./circle-mug-icon.svg";
import { ReactComponent as AppBottleGasCircle } from "./circle-bottled-gas.svg";
import { ReactComponent as AppWalletCircle } from "./circle-wallet-icon.svg";
import { ReactComponent as AppHotMug } from "./hotMug.svg";
import { ReactComponent as AppMeter } from "./meter.svg";
import { ReactComponent as AppPrepay } from "./prepay.svg";
import { ReactComponent as AppUserUnknown } from "./user-unknown.svg";
import { ReactComponent as Tick } from "./Tick.svg";
import { ReactComponent as CloseOverlay } from "./closeOverlay.svg";
import { ReactComponent as House } from "./house.svg";
import { ReactComponent as Details } from "./Details.svg";
import { ReactComponent as ExternalLink } from "./external-link.svg";
import { ReactComponent as Bar } from "./bar.svg";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as Clock } from "./clock.svg";

export {
  Edit,
  Help,
  DownloadBill,
  PropertySwitcher,
  Uncheck,
  UncheckError,
  Menu,
  Info,
  Error,
  Calendar,
  Check,
  Details,
  BottleGasLogo,
  Block,
  ArrowRight,
  ArrowDown,
  ArrowLeftRight,
  BroadBandLogo,
  ElectricityLogo,
  PipedGasLogo,
  LongArrowUp,
  LongArrowDown,
  WhiteCheck,
  Logo,
  SelectedTick,
  Primary,
  Primaryb,
  Close,
  Lock,
  Electricity,
  Gas,
  InProgress,
  Resolved,
  WelcomePack,
  AsbLogo,
  AnzLogo,
  BnzLogo,
  GenericCardLogo,
  CooperativeLogo,
  KiwiBankLogo,
  SbsLogo,
  TsbLogo,
  WestpacLogo,
  HsbcLogo,
  SwitchSvg,
  AppBank,
  AppBottledGas,
  AppHotMugCircle,
  AppWalletCircle,
  AppBottleGasCircle,
  BottledGas,
  AppBroadbandClock,
  AppBroadbandHome,
  AppCalendar,
  AppElectricity,
  AppEnvelope,
  AppHotMug,
  AppMeter,
  AppPrepay,
  AppUserUnknown,
  Tick,
  CloseOverlay,
  House,
  ServiceEnvelope,
  BroadbandHomeBold,
  ExternalLink,
  BroadBandCircle,
  ServiceCircle,
  Bar,
  Cross,
  Clock,
};
