import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { PromotionsPath, PromotionsVersion } from '..';
import { PromotionsApiResponse } from './models/promotions';

export const getPromotions = (
  version: PromotionsVersion,
  promoCode: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).get<PromotionsApiResponse>(
    PromotionsPath[version](promoCode)
  );
};
