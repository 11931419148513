/*eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Icon from "../Icon";
import Button from "../Button";
import ScrollContainer from "react-indiana-drag-scroll";
import { WhiteCheck, Close, ArrowRight } from "../../Assets/Icons";
import HTML from "../HTML";
import NavigationDots from "../NavigationDots";
import "./PlanCarousel.scss";
import { cookieVariables } from "../../../Config/Constants";
import { getCookie } from "../../../Utils/CookiesUtil";
import { capitalize } from "lodash";

const modalRoot = document.getElementById("modalRoot");

const PlanCarousel = (props) => {
  const [itemChecked, setItemChecked] = useState(null);
  const [isModalActive, setIsModalActive] = useState(false);
  const [isUpdateGoodNights, setIsUpdateGoodNights] = useState(false);
  const [isTCModalActive, setIsTCModalActive] = useState(false);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [dragScroll, setDragScroll] = useState(0);
  const [controlsScroll, setControllsScroll] = useState(false);
  const step = 350;
  const isBillCompareResult = props.enrichedIds.length > 0 ? true : false;
  const [modal, setModal] = useState({
    content: "",
    tCButtonText: "",
    choseButtonText: "",
  });

  const refs = useRef([]);
  const registerRef = useCallback((element) => refs.current.push(element), []);

  useEffect(() => {
    if (props.scrollToSelectedPlan) {
      refs.current[props.planIndex]?.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
    return () => props.resetScrollToSelectedPlan?.();
  });

  useEffect(() => {
    setItemChecked(props.planIndex);
  }, [props.planIndex]);

  const onItemSelect = (index) => {
    setIsModalActive(false);
    setItemChecked(itemChecked !== index ? index : null);
    if (itemChecked !== index) {
      props.onPress(props.data[index], index);
    } else {
      props.onPress({}, null);
    }
  };

  const returnPlanUI = (plan) => {
    return (
      <div>
        {plan.BadgeName && (
          <div className="planCarousel_container_block_label">
            <span>{plan.BadgeName}</span>
          </div>
        )}
        {plan.BadgeImageNew.length > 0 && (
          <div className="planCarousel_container_block_logo-image">
            <img src={plan.BadgeImageNew[0].DesktopImage.Url} alt="" aria-hidden="true" />
          </div>
        )}
        <div className="planCarousel_container_block_name">
          {plan.Name}
        </div>
        <div className="planCarousel_container_block_description">
          <HTML html={plan.PromoText} />
        </div>

        <div className="planCarousel_container_block_subHeader">
          {plan.TermText}
        </div>
      </div>
    );
  };

  const onModalShowGoodNights = (updateGoodNights) => {
    setIsModalActive(true);
    setIsUpdateGoodNights(updateGoodNights);
    setModal({
      header: props.dgInfo.title,
      content: props.dgInfo.desc,
    });
  };

  const onModalShow = (plan, index) => {
    setIsModalActive(true);
    setIsUpdateGoodNights(false);
    setModal({
      header: plan.Name,
      content: plan.Description,
      card: returnPlanUI(plan),
      tcButtonText: 'Ts and Cs',
      buttonIndex: index,
      plan: plan,
    });
  };

  const renderSelectButton = (
    index,
    plan,
    className,
    updateGoodNights
  ) => (
    <Button
      className={classNames(
        "planCarousel_container_block_buttons_button",
        updateGoodNights
          ? "planCarousel_container_block_buttons_button--disabled"
          : "",
        className,
        itemChecked === index
          ? "planCarousel_container_block_buttons_button_buttonChecked"
          : ""
      )}
      handleClick={() => onItemSelect(index)}
      disabled={updateGoodNights ? true : false}
      id={`choose-${plan.Name}-button`}
      text={
        itemChecked === index ? (
          <Icon
            icon={<WhiteCheck />}
            size={28}
            className="planCarousel_container_block_buttons_button_icon"
          />
        ) : (
          <div className="planCarousel_container_block_buttons_button_buttonText">
            {capitalize(
              updateGoodNights ? "Selection not available" : "Choose plan"
            )}
          </div>
        )
      }
    />
  );

  const scrollToElement = (params, isIndex) => {
    setControllsScroll(true);
    let index = 0;
    if (scrollIndex >= 0) {
      if (isIndex) {
        index = params;
      } else {
        index =
          scrollIndex +
          (params === "right"
            ? scrollIndex < props.data.length - 1
              ? 1
              : 0
            : scrollIndex > 0
              ? -1
              : 0);
      }
    }
    const name = props.data[index]?.ID;
    const element = document.getElementById(name);
    setScrollIndex(index);
    if (element) {
      // setTimout gives enough time for the element to be rendered
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }, 0);
      if (index === props.data.length - 1) {
        setScrollIndex(index);
      }
    }
  };

  const sharedPlanIdCookieName = cookieVariables.sharedPlanIdCookieName;
  const planIdCookie = getCookie(sharedPlanIdCookieName);

  useEffect(() => {
    if (!props.serviceAddress && props.planIndex > -1) {
      scrollToElement(props.planIndex, true);
    }
  }, [props.data, props.planIndex, planIdCookie, props.serviceAddress]);

  const onScrollStart = (event) => {
    setDragScroll(event);
  };

  const onScrollEnd = (event) => {
    if (!controlsScroll) {
      const scrollDifference = event - dragScroll;
      const scrollDitanse = Math.round(scrollDifference / step) * step;
      const numberOfSteps = Math.round(scrollDitanse / step);
      if (numberOfSteps >= 0) {
        setScrollIndex(scrollIndex + numberOfSteps);
      } else if (numberOfSteps < 0) {
        setScrollIndex(scrollIndex - Math.abs(numberOfSteps));
      }
      setControllsScroll(false);
    } else {
      setControllsScroll(false);
    }
  };

  //Render Enriched Cards for Bill Compare
  const renderEnrichedPlanCards = (list) => (
    <ScrollContainer
      className="planCarousel_container"
      onStartScroll={(event) => onScrollStart(event)}
      onEndScroll={(event) => onScrollEnd(event)}
    >
      {list.map((item, index) => {
        const isEnriched = props.enrichedIds?.find(
          (result) => result.planId === item.ID
        );

        return (
          <div className="planCarousel_billCompare" key={index}>
            <div
              className={
                isEnriched
                  ? "planCarousel_enrichedCards"
                  : "planCarousel_enrichedCards planCarousel_enrichedCards--disabled"
              }
            >
              You would've saved
              <span>&nbsp;${isEnriched ? isEnriched.amt.toFixed(2) : 0}</span>
              &nbsp; on your bill!
            </div>
            {getPlanCarousel(index, plan, false)}
          </div>
        );
      })}
    </ScrollContainer>
  );

  const getPlanCarousel = (index, plan, updateGoodNights) => {
    return (
      <div
        key={index}
        className="planCarousel_container_block"
        id={plan.ID}
        data-productid={plan.ID}
        ref={registerRef}
      >
        {returnPlanUI(plan)}
        <div className="planCarousel_container_block_buttons">
          {renderSelectButton(index, plan, "", updateGoodNights)}
          <Button
            id={`learn-${plan.Name}-button`}
            text="Learn more"
            handleClick={() =>
              updateGoodNights
                ? onModalShowGoodNights(updateGoodNights)
                : onModalShow(plan, index)
            }
            className="planCarousel_container_block_buttons_button_link"
          />
        </div>
      </div>
    );
  };

  //Render Plan Cards for Customer Journey
  const renderListItem = (list) => (
    <ScrollContainer
      className="planCarousel_container"
      onStartScroll={(event) => onScrollStart(event)}
      onEndScroll={(event) => onScrollEnd(event)}
    >
      {list.map((item, index) => {
        const updateGoodNights =
          props.isDistributedGenerationEligible &&
          props.distributedGenerationPlanId.indexOf(item.ID) >
          -1;
        return getPlanCarousel(index, item, updateGoodNights);
      })}
    </ScrollContainer>
  );

  const scrollRight = () => {
    scrollToElement("right", false);
  };

  const scrollLeft = () => {
    scrollToElement("left", false);
  };

  const showArrows = props.data.length > 3;

  return (
    <div className="planCarousel" id="planCarousel">
      {isModalActive &&
        ReactDOM.createPortal(
          <div className="planCarousel_modal">
            <div
              className={
                isUpdateGoodNights
                  ? "planCarousel_modal_container planCarousel_modal_container_good-nights"
                  : "planCarousel_modal_container"
              }
            >
              <div className="planCarousel_modal_container_mini-card">
                <div
                  className="planCarousel_modal_container_mini-card_close"
                  onClick={() => setIsModalActive(false)}
                >
                  <Icon
                    icon={<Close />}
                    className="planCarousel_modal_container_mini-card_close_icon"
                  />
                </div>
                {!isUpdateGoodNights && (
                  <div className="planCarousel_modal_container_block--card">
                    {modal.card}
                  </div>
                )}
              </div>
              <div className="planCarousel_modal_container_block">
                <div className="planCarousel_modal_container_block_header">
                  {modal.header}
                </div>
                <HTML html={modal.content} />
                {!isUpdateGoodNights && (
                  <div className="planCarousel_modal_container_block_buttons">
                    <Button
                      id="TC"
                      text={modal.tcButtonText}
                      handleClick={() => setIsTCModalActive(true)}
                      className="planCarousel_modal_container_block_buttons_button-tc"
                      primaryBlack
                      customStyle={{
                        backgroundColor: props.constants.buttonBBColor,
                      }}
                    />
                    {renderSelectButton(
                      modal.buttonIndex,
                      modal.plan,
                      "planCarousel_modal_container_block_buttons_select"
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>,
          modalRoot
        )}
      {isTCModalActive &&
        ReactDOM.createPortal(
          <div className="planCarousel_modal">
            <div
              className={classNames(
                "planCarousel_modal_container",
                "planCarousel_modal_container--column"
              )}
            >
              <div
                className="planCarousel_modal_container_mini-card_close"
                onClick={() => setIsTCModalActive(false)}
              >
                <Icon
                  icon={<Close />}
                  className="planCarousel_modal_container_mini-card_close_icon"
                />
              </div>
              <div className="planCarousel_modal_container_title">
                {modal.plan.TermsAndConditionsTitle}
              </div>
              <div>
                <HTML html={modal.plan.TermsAndConditionsBodyText} />
              </div>
              <div className="planCarousel_modal_container_single-button">
                <a href={modal.plan.TermsAndConditionsCta[0].InternalFileLink[0]
                  .AttachmentFile.Url} target="_blank" rel="noopener noreferrer">
                  <div
                    className="planCarousel_modal_container_single-button_button-tc"
                    style={{ backgroundColor: props.constants.buttonBBColor }}
                  >
                    {modal.plan.TermsAndConditionsCta[0].Name}
                  </div>
                </a>
              </div>
            </div>
          </div>,
          modalRoot
        )}
      <div className="planCarousel_listContainer">
        {showArrows && (
          <div
            className="planCarousel_arrow planCarousel_arrow--left"
            onClick={scrollLeft}
          >
            <Icon icon={<ArrowRight />} size={28} />
          </div>
        )}
        {isBillCompareResult
          ? renderEnrichedPlanCards(props.data)
          : renderListItem(props.data)}
        {showArrows && (
          <div
            className="planCarousel_arrow planCarousel_arrow--right"
            onClick={scrollRight}
          >
            <Icon icon={<ArrowRight />} size={28} />
          </div>
        )}
      </div>
      <NavigationDots
        elements={props.data}
        index={scrollIndex}
        onClick={scrollToElement}
      />
    </div>
  );
};

export default PlanCarousel;
