/**
 * Use this middleware only and only for iJoin store.
 */
import * as Analytics from "../../Utils/analytics";
import * as actionTypes from "../Actions/actionTypes";
import { getPostalAddress } from "../../Utils/selectors";

export default (store) => (next) => (action) => {
  const state = store.getState();
  switch (action.type) {
    case actionTypes.TRIGGER_MY_DETAILS_JOURNEY:
      Analytics.triggerMyDetailsJourney();
      break;

    case actionTypes.TRIGGER_PURCHASE:
      Analytics.triggerPurchase(
        state.Readonly.ApiPlan,
        state.Services,
        state.Property.PropertyInfo.ElectricityICP,
        state.Payment.PromoCode
      );
      break;

    case actionTypes.TRIGGER_MY_DETAILS_PAGEVIEW:
      Analytics.triggerMyDetailsPageview();
      break;

    case actionTypes.TRIGGER_CUSTOMER_INFO:
      Analytics.triggerCustomerInfo(
        state.Customer.CustomerInfo.EmailAddress,
        state.Customer.CustomerInfo.FirstName,
        state.Customer.CustomerInfo.LastName,
        state.Customer.CustomerInfo.PhoneNumber
      );
      break;

    case actionTypes.TRIGGER_PROPERTY_INFO:
      Analytics.triggerPropertyInfo(getPostalAddress(state));
      break;

    case actionTypes.TRIGGER_SUCCESS_LOGIN:
      Analytics.triggerSuccessLogin();
      break;

    case actionTypes.TRIGGER_PURCHASE_EXPRESS:
      Analytics.triggerPurchaseExpress(
        state.Property.PropertyInfo.ElectricityICP,
        state.Payment.PromoCode
      );
      break;

    default:
  }
  return next(action);
};
