import React from "react";
import { HTML, Modal } from "react-components";
import { AppError as Constants } from "../Config/Constants";

const AppError = ({ message = undefined }) => (
  <Modal
    isActive={true}
    buttonTextPrimary={Constants.buttonLabel}
    handlePrimaryClick={() => {
      window.location.href = process.env.NX_IJOIN_START_URL;
    }}
    content={
      message ? <HTML html={message} /> : <HTML html={Constants.message} />
    }
  />
);

export default AppError;
