export const KenticoPlanContentType = 'Contact.PlanDetails';
export const KenticoBroadbandContentType = 'Contact.BroadbandSpeedOptions';
export const KenticoMessageContentType = 'Contact.Messages';

type AttachmentMetadata = {
  Original: unknown | null;
  OptimizationParameters: unknown | null;
  Identifier: Record<string, unknown> | null;
  Name: string;
  Extension: string;
  Size: number;
  Width: number | null;
  Height: number | null;
  LastModified: string;
};

type ImageData = {
  Metadata: AttachmentMetadata;
  Url: string;
};

type Image = {
  ImageAltText: string;
  DesktopImage: ImageData;
  TabletImage: ImageData | null;
  MobileImage: ImageData | null;
};

type AttachmentFile = {
  Metadata: AttachmentMetadata;
  Url: string;
};

type InternalFileLinkItem = {
  AttachmentName: string;
  AttachmentFile: AttachmentFile;
};

type CTA = {
  Name: string;
  InternalFileLink: InternalFileLinkItem[];
  ExternalLink: string;
  QueryParameter: string;
  OpenInNewTab: boolean;
  OldSiteCoreUrl: string;
};

interface KenticoPlanContentItemData {
  Name: string;
  AnchorTag: string;
  Description: string;
  AllPlansDescription: string;
  AllPlansBenefits: string;
  PromoText: string;
  TermText: string;
  RewardText: string;
  BadgeName: string;
  BadgeImageNew: Image[];
  TermsAndConditionsTitle: string;
  TermsAndConditionsBodyText: string;
  TermsAndConditionsCta: CTA[];
}

export type KenticoPlanContentItem = {
  ID: string;
  type: typeof KenticoPlanContentType;
} & KenticoPlanContentItemData;

interface KenticoBroadbandContentItemData {
  LearnMoreDescription: string;
}

export type KenticoBroadbandContentItem = {
  ID: string;
  type: typeof KenticoBroadbandContentType;
} & KenticoBroadbandContentItemData;

interface KenticoMessageContentItemData {
  Content: string;
  MessagesTitle: string;
  MessagesImage: Image[];
  MessagesIcon: string;
  MessagesColour: string;
  MessageNumber: string;
  MessagesLabel: string;
  MessagesLink: string;
  CampaignId: string;
  Message: string;
  MessagesContent2: string;
  MessagesContent3: string;
  MessagesContent4: string;
  MessagesLabel2: string;
  MessagesLabel3: string;
  MessagesLabel4: string;
  MessagesImage2: Image[];
  MessagesImage3: Image[];
  MessagesSnippet: string;
}

export type KenticoMessageContentItem = {
  ID: string;
  type: typeof KenticoMessageContentType;
} & KenticoMessageContentItemData;

export type KenticoContentItem =
  | KenticoPlanContentItem
  | KenticoBroadbandContentItem
  | KenticoMessageContentItem;

export type KenticoPlanContentApiResponse = {
  contentID: string;
  contentType: typeof KenticoPlanContentType;
  data: KenticoPlanContentItemData;
}[];

export type KenticoBroadbandContentApiResponse = {
  contentID: string;
  contentType: typeof KenticoBroadbandContentType;
  data: KenticoBroadbandContentItemData;
}[];

export type KenticoMessageContentApiResponse = {
  contentID: string;
  contentType: typeof KenticoMessageContentType;
  data: KenticoMessageContentItemData;
}[];

export type KenticoContentApiResponse =
  | KenticoPlanContentApiResponse
  | KenticoBroadbandContentApiResponse
  | KenticoMessageContentApiResponse;
