import React, { useCallback } from "react";
import classNames from "classnames";
import Tooltip from "../Tooltip";
import "./RadioButton.scss";
import { noop } from "react-use/lib/misc/util";

const RadioButton = ({
  className = undefined,
  onChange: onChangeExternal,
  value,
  checked = false,
  disabled = false,
  onKeyDown = undefined,
  name = undefined,
  validateItself = undefined,
  label = undefined,
  required = undefined,
  description = undefined,
  tooltip = undefined,
}) => {
  const radioInputName = classNames("radioButton_input", {
    checked,
  });

  const radioClassName = classNames(
    className,
    disabled ? "radioButton radioButton_disabled" : "radioButton"
  );
  const onSelect = useCallback(() => {
    if (!checked) {
      onChangeExternal(value);
    }
  }, [onChangeExternal, value, checked]);
  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onSelect();
    },
    [onSelect]
  );

  return (
    <div className="radioButton_wrapper">
      <label htmlFor={name} className={radioClassName} onClick={onClick}>
        <input
          id={name}
          name={name}
          className={radioInputName}
          type="radio"
          checked={!!checked}
          onKeyDown={onKeyDown}
          onChange={noop}
          value={value}
        />
        <div
          id={name ? `${name}Label` : undefined}
          className="radioButton_label"
        >
          <svg
            className="radioButton_svg"
            fill="currentColor"
            preserveAspectRatio="xMidYMid meet"
            height="20"
            width="20"
            viewBox="0 0 20 20"
          >
            <circle
              className="radioButton_radioOutline"
              cx="10"
              cy="10"
              r="8"
              fill="none"
              stroke={
                validateItself && required && !checked ? "#DD353F" : "#32122f"
              }
              strokeWidth="2"
            />
            <circle
              className="radioButton_radioDot"
              cx="10"
              cy="10"
              r="4"
              fill="#32122f"
            />
          </svg>
          <span id={name ? name + "Text" : null} className="radioButton_text">
            {label || value}
          </span>
        </div>
        {description && (
          <div className="radioButton_description">{description}</div>
        )}
      </label>
      {tooltip && <Tooltip className="radioButton_tooltip">{tooltip}</Tooltip>}
    </div>
  );
};
export default RadioButton;
