import React from "react";
import classNames from "classnames";
import noop from "nop";

import "./Tooltip.scss";

const Tooltip = ({ children, className = undefined, onMouseEnter = noop }) => (
  <div
    className={classNames(["tooltip", className])}
    onMouseEnter={onMouseEnter}
  >
    <div className="tooltip_info">i</div>
    <div className="tooltip_content">{children}</div>
  </div>
);

export default Tooltip;
