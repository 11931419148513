import React from "react";
import classNames from "classnames";
import HTML from "../HTML";

const Error = ({
  messages = {},
  className = "",
  errorClassName = "",
  errorMessage = "",
}) => (
  <div className={classNames("rates", className)}>
    <div className="rates_top-row">
      <div
        className={classNames(
          "rates_error",
          errorClassName ? errorClassName : ""
        )}
      >
        <HTML html={errorMessage ? errorMessage : messages.error} />
      </div>
    </div>
  </div>
);

export default Error;
