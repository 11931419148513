import {
  persistSessionId,
  getSessionId,
  clearSessionId,
  persistLocalData,
  hydrateLocalData,
} from "../../Utils/session";
import { updateAuth } from "../Actions/CustomerJoin/MyDetailsActions";
import {
  PERSIST_SESSION_ID,
  CLEAR_SESSION_ID,
  PERSIST_LOCAL_DATA,
  HYDRATE_LOCAL_DATA,
} from "../Actions/actionTypes";

export default () => (next) => (action) => {
  switch (action.type) {
    case PERSIST_SESSION_ID:
      // Persist sessionId in local storage & update store with current session id
      next(updateAuth(persistSessionId() || getSessionId(), 0));
      break;

    case CLEAR_SESSION_ID:
      // Removes session id from app
      clearSessionId();
      // Update store with current session id
      next(updateAuth("", 0));
      break;

    case PERSIST_LOCAL_DATA:
      persistLocalData(action.payload);
      break;

    case HYDRATE_LOCAL_DATA:
      return next({
        type: HYDRATE_LOCAL_DATA,
        payload: hydrateLocalData(action.keys),
      });

    default:
  }
  return next(action);
};
