import React, {
  useEffect,
  useRef,
  useCallback,
  useReducer,
  RefCallback,
} from "react";
import { animated } from "react-spring";
import Icon from "../Icon";
import "./DateOfBirthInput.scss";
import { Calendar } from "../../Assets/Icons";
import noop from "nop";
import { InputProps } from "@material-ui/core/Input";
import {
  DateOfBirthInvalidReason,
  DateOfBirthValue,
  getDateOfBirthValidationReason,
} from "../../Utils/ValidationUtil";

export interface DateOfBirthInputProps {
  value?: DateOfBirthValue;
  handleError?(error: boolean): void;
  handleErrorMessage?(reason?: string | DateOfBirthInvalidReason): void;
  required?: boolean;
  inputProps?: Exclude<InputProps, "onBlur"> & {
    onBlur?(): void;
    ref?: RefCallback<HTMLElement>;
  };
  handleChange;
  hasError?: boolean;
  placeholder?: string;
  errorMessage?: string;
  labelText?: string;
  validateItself?: boolean;
  minErrorMessage?: string;
  maxErrorMessage?: string;
  invalidErrorMessage?: string;
  requiredErrorMessage?: string;
  min?: number;
  max?: number;
  showCalender?: boolean;
}

function DateOfBirthField({
  item,
  isFocused,
  noValues,
  placeholder,
  hasValue,
  inputType,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
}) {
  const onFocusHandler = useCallback(() => onFocus(item.period), [
    onFocus,
    item.period,
  ]);
  const onBlurHandler = useCallback(() => onBlur(item.period), [
    onBlur,
    item.period,
  ]);
  return (
    <div>
      <input
        name={item.period}
        type={inputType}
        value={item.value}
        placeholder={
          isFocused && noValues && placeholder && item.placeholder && !hasValue
            ? placeholder
            : ""
        }
        style={{
          maxWidth: hasValue && item.period !== "year" ? "28px" : item.maxWidth,
        }}
        onChange={onChange}
        maxLength={item.length + 1}
        onKeyDown={onKeyDown}
        ref={item.ref}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        pattern="[0-9]*"
      />
      {item.period === "day" && hasValue ? <span>/</span> : undefined}
      {item.period === "month" && hasValue ? <span>/</span> : undefined}
    </div>
  );
}

const DateOfBirthInput = ({
  value = DateOfBirthInput.defaultProps.value,
  handleError = DateOfBirthInput.defaultProps.handleError,
  handleErrorMessage,
  required = false,
  inputProps,
  handleChange,
  hasError = false,
  placeholder,
  errorMessage,
  labelText,
  max: maxAge,
  min: minAge,
  requiredErrorMessage,
  invalidErrorMessage,
  minErrorMessage,
  maxErrorMessage,
  validateItself,
  showCalender = true,
}: DateOfBirthInputProps) => {
  const inputDay = useRef<HTMLInputElement>(null);
  const inputMonth = useRef<HTMLInputElement>(null);
  const inputYear = useRef<HTMLInputElement>(null);
  const [, onFocusChange] = useReducer((value) => value + 1, 0);
  const focusedRef = useRef<string | undefined>(undefined);
  const focused = focusedRef.current;
  const isFocused = !!focused;
  const hasValue = value.day || value.month || value.year;
  const noValues = !hasValue;
  const hasFullValue = value.day && value.month && value.year;
  const showError = hasError && (validateItself || hasFullValue);
  const inputType = "text";
  const date = [
    {
      period: "day",
      value: value.day,
      placeholder: true,
      length: 2,
      ref: inputDay,
      maxWidth: "150px",
    },
    {
      period: "month",
      value: value.month,
      placeholder: false,
      length: 2,
      ref: inputMonth,
      maxWidth: "0",
    },
    {
      period: "year",
      value: value.year,
      placeholder: false,
      length: 4,
      ref: inputYear,
      maxWidth: value.year ? "60px" : "0",
    },
  ];
  const { day, month, year } = value;

  const lastHasError = useRef(hasError);
  lastHasError.current = hasError;
  useEffect(() => {
    if (isFocused && !lastHasError.current) {
      return;
    }

    const reason = getDateOfBirthValidationReason(
      { day, month, year },
      handleChange,
      {
        minAge,
        maxAge,
      }
    );

    handleError(!!reason);

    const reasons: Record<DateOfBirthInvalidReason, string | undefined> = {
      REQUIRED: requiredErrorMessage,
      MAX_AGE: maxErrorMessage,
      MIN_AGE: minErrorMessage,
      INVALID: invalidErrorMessage,
    };
    handleErrorMessage?.(
      reason ? reasons[reason] || minErrorMessage || reason : undefined
    );
  }, [
    requiredErrorMessage,
    invalidErrorMessage,
    minErrorMessage,
    maxErrorMessage,
    day,
    month,
    year,
    handleError,
    required,
    hasError,
    isFocused,
    handleChange,
    minAge,
    maxAge,
    handleErrorMessage,
  ]);

  const onKeyDownHandler = useCallback((e) => {
    const period = e.target.name;
    const isLeft = e.keyCode === 37;
    const isRight = e.keyCode === 39;
    const isBackspace = e.keyCode === 8;
    const backMove = (isLeft || isBackspace) && e.target.selectionStart === 0;
    const towardsMove = isRight && e.target.selectionStart === 2;
    const day = inputDay.current;
    const month = inputMonth.current;
    const year = inputYear.current;
    // Using preventDefault + stopPropagation prevents jumping a character when
    // focusing into another input
    //
    // We additionally try and reset the to the end of the input when going backwards
    // but it is dependent on the browser behaving nicely
    //
    // This is just best effort. See onChangeHandler for how we jump forward
    // while typing
    if (period === "day") {
      if (towardsMove) {
        if (!isBackspace) {
          e.preventDefault();
          e.stopPropagation();
        }
        month?.focus();
      }
    } else if (period === "month") {
      if (towardsMove && year) {
        if (!isBackspace) {
          e.preventDefault();
          e.stopPropagation();
        }
        inputYear.current?.focus();
      } else if (backMove && day) {
        e.preventDefault();
        e.stopPropagation();
        const initial = day.value;
        day.value = "";
        day.value = initial;
        day.selectionStart = day.selectionEnd = initial.length;
        day.focus();
      }
    } else if (period === "year") {
      if (backMove && month) {
        if (!isBackspace) {
          e.preventDefault();
          e.stopPropagation();
        }
        // Reset focus for this input to the end of the element
        const initial = month.value;
        month.value = "";
        month.value = initial;
        month.selectionStart = month.selectionEnd = initial.length;
        month.focus();
      }
    }
  }, []);

  const onChange = inputProps?.onChange;
  const onChangeHandler = useCallback(
    (e) => {
      let updateValue = e.target.value;
      let period = e.target.name;
      const month = inputMonth.current;
      const year = inputYear.current;
      if (period === "year") {
        if (updateValue.length > 4) {
          // Can't type any more characters
          return;
        }
      } else if (period === "month") {
        if (updateValue.length > 2) {
          // Take all characters after two, swap to next input
          updateValue = updateValue.split("").slice(2).join("");
          period = "year";
          if (year) {
            year.value = "";
            // This will also be updated from react land, but this is to
            // force focus immediately to the end of the value
            year.value = updateValue;
            year.selectionStart = year.selectionEnd = updateValue.length;
            year.focus();
          }
        }
      } else if (period === "day") {
        if (updateValue.length > 2) {
          // Take all characters after two, swap to next input
          updateValue = updateValue.split("").slice(2).join("");
          period = "month";
          if (month) {
            month.value = "";
            // See year.value above
            month.value = updateValue;
            month.selectionStart = month.selectionEnd = updateValue.length;
            month.focus();
          }
        }
      } else {
        if (e.keyCode === 9) {
          return e.preventDefault();
        }
      }
      const dateObject = { ...value, [period]: updateValue };
      handleChange(dateObject);
      if (onChange) {
        onChange(e);
      }
    },
    [handleChange, onChange, value]
  );

  const onFocus = useCallback(
    (period) => {
      focusedRef.current = period;
      onFocusChange();
    },
    [onFocusChange]
  );
  const onBlur = useCallback(
    (period) => {
      if (focusedRef.current === period) {
        focusedRef.current = "";
        onFocusChange();
      }
    },
    [onFocusChange]
  );

  const { onBlur: onExternalBlur } = inputProps ?? {};
  const onExternalBlurRef = useRef(onExternalBlur);
  onExternalBlurRef.current = onExternalBlur;
  useEffect(() => {
    if (focused) {
      return;
    }
    const timeout = setTimeout(() => {
      const blur = onExternalBlurRef.current;
      if (typeof blur === "function") {
        blur();
      }
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [focused]);

  const focusedOnDob = useRef(false);
  if (!isFocused) {
    focusedOnDob.current = false;
  }
  const focusOnDob = useCallback(() => {
    if (
      !inputDay.current?.value &&
      !inputMonth.current?.value &&
      !inputYear.current?.value &&
      !focusedOnDob.current
    ) {
      focusedOnDob.current = true;
      focusedRef.current = "day";
      inputDay.current?.focus();
    }
  }, []);

  return (
    <div className="dobInputContainer_field">
      {labelText && (
        <animated.label
          id={value.day}
          htmlFor={value.day}
          className="dobInputContainer_label required"
          onClick={focusOnDob}
        >
          {labelText}
        </animated.label>
      )}
      <div
        className={
          showError ? "inputContainer inputContainer--error" : "inputContainer"
        }
        onClick={focusOnDob}
      >
        <div
          className="dobInputContainer_dob"
          ref={inputProps && inputProps.ref}
        >
          {showCalender && (
            <div className="dobInputContainer_dob_calendar">
              <Icon
                name="calendar"
                icon={<Calendar />}
                className="dobInputContainer_dob_calendar_icon"
              />
            </div>
          )}
          {date.map((item, index) => (
            <DateOfBirthField
              key={index}
              item={item}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChangeHandler}
              onKeyDown={onKeyDownHandler}
              placeholder={placeholder}
              isFocused={isFocused}
              inputType={inputType}
              hasValue={hasValue}
              noValues={noValues}
            />
          ))}
        </div>
      </div>
      {showError && (
        <label
          id={value.day}
          htmlFor="day"
          className="dobInputContainer_label--error"
          onClick={focusOnDob}
        >
          {errorMessage}
        </label>
      )}
    </div>
  );
};

DateOfBirthInput.defaultProps = {
  handleError: noop,
  value: {},
};

export default DateOfBirthInput;
