import moment from "moment";

const VALID_ACCOUNT_NUMBER_LENGTH = 9;

export const validateAccountNumber = (accountNumber: unknown): boolean => {
  const lengthOfAccountNumber =
    typeof accountNumber === "string" ? accountNumber.length : 0;
  return lengthOfAccountNumber === VALID_ACCOUNT_NUMBER_LENGTH;
};

const validateBBAccountNumber = (number: string): boolean => {
  const regexp = new RegExp(/^[A-Za-z0-9@+-.]{2,150}$/);
  return !!(number && regexp.test(number));
};

const validateIcpNumber = (number: string): boolean => {
  return !!(number && number.length === 15);
};

export const validateEmailAddress = (email: unknown): boolean => {
  const regexp = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return typeof email === "string" && email !== "" && regexp.test(email.trim());
};

export const validatePassword = (password: unknown): boolean => {
  const regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/);
  return (
    typeof password === "string" && regexp.test(password) && password !== ""
  );
};

export const validateDate = (minDate, maxDate, date, handleError) => {
  let isInValid = false;
  isInValid = date === null || isInValid;
  isInValid =
    minDate && date
      ? validateFirstDateBeforeSecond(date, minDate) || isInValid
      : isInValid;
  isInValid =
    maxDate && date
      ? validateFirstDateBeforeSecond(maxDate, date) || isInValid
      : isInValid;
  return handleError(isInValid);
};

export const validateFirstDateBeforeSecond = (first, second) => {
  return first.getTime() < second.getTime();
};

const validatePhoneNumber = (phone: string): boolean => {
  const regexp = new RegExp(
    /(^02(0|1|2|5|7|8|9)[0-9 ]{6,13}$)|(^0(3|4|6|7|9)[0-9 ]{6,13}$)|(^0(800|508)[0-9 ]{6,14}$)/
  );
  return !!(phone && regexp.test(phone));
};

const validateDriverLicence = (licence: string): boolean => {
  const regexp = new RegExp(/^([A-Za-z]{2})([0-9]{6})$/);
  return !!(licence && regexp.test(licence));
};

const validateCardVersionNumber = (number: string): boolean => {
  const regexp = new RegExp(/^[0-9]{3}$/);
  return !!(number && regexp.test(number));
};

const validateName = (name, setValue) => {
  if (name && name.length > 0) {
    const onlyLetters = name
      // eslint-disable-next-line no-useless-escape
      .replace(/[\"\~`!@#$%^&()_={}[\]:;,.<>+\/?]+|\d+|^\s+$/g, "")
      .replace(/\s+/gi, " ");
    const uppercaseFirstLetter =
      onlyLetters && onlyLetters[0]
        ? onlyLetters[0].toUpperCase() + onlyLetters.substring(1)
        : onlyLetters;
    setValue(uppercaseFirstLetter);
  } else {
    setValue(name);
  }
};

export interface DateOfBirthValue {
  day?: string;
  month?: string;
  year?: string;
}

export type DateOfBirthInvalidReason =
  | "REQUIRED"
  | "INVALID"
  | "MIN_AGE"
  | "MAX_AGE";

/**
 *
 * @param date
 * @param setValue
 */

export interface DateOfBirthValidationOptions {
  minAge?: number;
  maxAge?: number;
}

export function validateDateOfBirth(
  date: DateOfBirthValue,
  setValue?: (value: DateOfBirthValue) => void,
  options: DateOfBirthValidationOptions = {}
) {
  return !getDateOfBirthValidationReason(date, setValue, options);
}

export function getDateOfBirthValidationReason(
  date: DateOfBirthValue,
  setValue?: (value: DateOfBirthValue) => void,
  options: DateOfBirthValidationOptions = {}
): DateOfBirthInvalidReason | undefined {
  if (!(date && date.day && date.month && date.year)) {
    return "REQUIRED";
  }
  let day = date.day.replace(/\D/g, "");
  let month = date.month.replace(/\D/g, "");
  const year = date.year.replace(/\D/g, "");
  if (day.length === 1) {
    day = day.padStart(2, "0");
  } else if (day.length > 2) {
    day = day.substr(0, 2);
  }
  if (month.length === 1) {
    month = month.padStart(2, "0");
  } else if (month.length > 2) {
    month = month.substr(0, 2);
  }
  if (year.length !== 4 || day.length !== 2 || month.length !== 2) {
    return "INVALID";
  }
  const dateInstance = new Date(`${year}/${month}/${day}`);
  const nowInstance = new Date();
  if (isNaN(dateInstance.getTime())) {
    return "INVALID";
  }
  if (dateInstance.getTime() > nowInstance.getTime()) {
    return "INVALID";
  }
  if (
    dateInstance.getDate() !== parseInt(date.day) ||
    dateInstance.getMonth() + 1 !== parseInt(date.month) ||
    dateInstance.getFullYear() !== parseInt(date.year)
  ) {
    return "INVALID";
  }
  if (dateInstance.getFullYear() > nowInstance.getFullYear()) {
    return "INVALID";
  }
  const age = getAge(dateInstance);
  if (options.maxAge && age > options.maxAge) {
    return "MAX_AGE";
  } else if (options.minAge && age < options.minAge) {
    return "MIN_AGE";
  }
  if (day !== date.day || month !== date.month) {
    setValue?.({ day, month, year });
  }
  return undefined;
}

function getAge(date: Date): number {
  return moment().diff(date, "years", false);
}

const validateStreetNumber = (value: string): boolean => {
  const regexp = new RegExp(/^\d+(\/[a-zA-Z0-9'-]*)?$/);
  return !!(value && regexp.test(value));
};

const validatePostcode = (value: string): boolean => {
  const regexp = new RegExp(/^\d{4}$/);
  return !!(value && regexp.test(value));
};

export const validate = (e, setValue, validationType, touched = false) => {
  e.preventDefault();
  const value = e.target.value;
  let regexPattern;
  let hasError = false;
  switch (validationType) {
    case "price":
      regexPattern = /^[0-9]\d*(\.\d{0,2})?$/;
      if (value === "") setValue(value);
      if (touched && value >= 1) {
        hasError = false;
      }
      if (touched && value < 1) {
        hasError = true;
      }

      if (regexPattern && regexPattern.test(value)) setValue(value);

      return hasError;
    case "accountNumber":
      regexPattern = /(\d{2}-\d{4}-\d{7}-\d{2,3})/;
      setValue(e.target.value);
      if (touched && regexPattern.test(e.target.value)) {
        hasError = false;
      }
      // return true;
      if (touched && !regexPattern.test(e.target.value)) {
        hasError = true;
      }

      if (regexPattern && regexPattern.test(value)) setValue(value);

      return hasError;
    case "phoneNumber":
      if (value) {
        hasError = touched && !validatePhoneNumber(value);
      }
      setValue(value);
      return hasError;
    case "phoneNumberUnrestricted":
      if (value) {
        hasError = touched && !validatePhoneNumber(value);
      }
      setValue(value);
      return hasError;
    case "broadbandAccountNumber":
      if (value) {
        hasError = touched && !validateBBAccountNumber(value);
      }
      setValue(value);
      return hasError;
    case "invalidIcpLength":
      if (value) {
        hasError = touched && !validateIcpNumber(value);
      }
      setValue(value);
      return hasError;
    case "email":
      if (e.target.value) {
        hasError = touched && !validateEmailAddress(e.target.value);
      }
      setValue(e.target.value);
      return hasError;
    case "driverLicence":
      if (e.target.value) {
        hasError = touched && !validateDriverLicence(e.target.value);
      }
      setValue(e.target.value);
      return hasError;
    case "password":
      hasError = touched && !validatePassword(e.target.value);
      setValue(e.target.value);
      return hasError;
    case "cardVersionNumber":
      if (e.target.value) {
        hasError = touched && !validateCardVersionNumber(e.target.value);
      }
      setValue(e.target.value);
      return hasError;

    case "name":
      validateName(e.target.value, setValue);
      break;
    case "streetNumber":
      if (e.target.value) {
        hasError = touched && !validateStreetNumber(e.target.value);
      }
      setValue(e.target.value);
      return hasError;
    case "postcode":
      if (e.target.value) {
        hasError = touched && !validatePostcode(e.target.value);
      }
      setValue(e.target.value);
      return hasError;
    case "integer":
      hasError = touched && !/^\d+$/.test(e.target.value);
      setValue(e.target.value);
      return hasError;
    case "decimal":
      hasError = touched && !/^\d+(?:\.\d+)?$/.test(e.target.value);
      setValue(e.target.value);
      return hasError;
    default:
      if (setValue) setValue(value);
      break;
  }
};
