import React, { useState } from "react";
import Input from "../Input";
import Button from "../Button";
import { Tick } from "../../Assets/Icons";
import Loading from "../Loading";
import classNames from "classnames";
import "./PromoCode.scss";

const PromoCode = (props) => {
  const [hover, setHover] = useState(false);
  const {
    placeholder,
    buttonText,
    buttonTextHover,
    buttonTextApplied,
    maxLength,
    code,
    hasError,
    errorMessage,
    successMessage,
    invalidMessage,
    codeSubmitted,
    setCode,
    setCodeSumbitted,
    validateItself,
    handleError,
    isCodeValidating,
    buttonDisabled,
    inputProps,
  } = props;
  return (
    <div className="promoCode">
      {successMessage && (
        <div className="promoCode_banner">
          {successMessage ? successMessage : ""}
        </div>
      )}
      <div className="promoCode_field">
        <Input
          name="promoCode"
          className="promoCode_field_input"
          value={code}
          handleChange={(val) => {
            setCode(val);

            // mark it as not ready to validate when the promo code is changed
            // we only validate when clicking the Validate button
            setCodeSumbitted(false);
          }}
          maxLength={maxLength}
          labelText={placeholder}
          handleError={handleError}
          hasError={hasError}
          errorMessage={errorMessage}
          invalidMessage={invalidMessage}
          validateItself={validateItself}
          hideErrorIcon
          inputProps={inputProps}
        />
        <Button
          className={classNames(
            "promoCode_field_button",
            codeSubmitted && "promoCode_field_button_submitted"
          )}
          text={
            codeSubmitted
              ? buttonTextApplied
              : hover
              ? buttonTextHover
              : buttonText
          }
          type="button"
          handleClick={() => setCodeSumbitted(!!code)}
          icon={codeSubmitted ? <Tick /> : null}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => setHover(false)}
          disabled={buttonDisabled || codeSubmitted}
          primaryOnLight
        />
        {isCodeValidating && (
          <div className="promoCode_loader">
            <Loading customstyle={{ minWidth: "100%", minHeight: "0" }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoCode;
