import React, { useEffect, useCallback, useReducer } from "react";

import isEmpty from "lodash/isEmpty";

import { ArrowDown, Electricity } from "../../Assets/Icons";
import ExpandableCard from "../ExpandableCard";
import { INACTIVE_CONNECTION_STATUSES } from "../../../Config/Constants";

import "./ServiceCard.scss";
import ServiceCardField from "./ServiceCardField";
import ServiceModal from "./ServiceModal";

const initialState = {
  // UI States
  isExpanded: false,
  showManualSearchForm: false,
  showManualICPForm: false,
  // Transient Data
  searchResults: [],
  // Display Data
  icp: null,
  icpDetails: null,
  meterType: "-",
  isSwitching: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    // Card
    case "openCard":
      return {
        ...state,
        isExpanded: true,
      };
    case "closeCard":
      return {
        ...state,
        isExpanded: false,
      };
    // Modals
    case "openModal":
      return {
        ...state,
        showManualICPForm: action.payload.modalType === "ICP",
        showManualSearchForm: action.payload.modalType === "AddressSearch",
      };
    case "closeModal":
      return {
        ...state,
        showManualSearchForm: false,
        showManualICPForm: false,
      };
    // Search Results
    case "searchResults":
      return {
        ...state,
        showManualSearchForm: false,
        searchResults: action.payload.map(({ address, ...rest }) => ({
          name: address,
          ...rest,
        })),
        icp: null,
        icpDetails: null,
      };
    case "selectICP":
      return {
        ...state,
        showManualSearchForm: false,
        showManualICPForm: false,
        searchResults: [],
        icp: action.payload,
      };
    case "invalidICP":
      return {
        ...state,
        icp: null,
        icpDetails: null,
      };
    // ICP Data
    case "storeICPDetails":
      return {
        ...state,
        icpDetails: action.payload,
      };
    // Switches
    case "setSwitching":
      return { ...state, isSwitching: action.payload };
    case "setMeterType":
      return { ...state, meterType: action.payload };
    // Reset
    case "reset":
      return initialState;
    default:
      throw new Error(
        `ServiceCard Reducer, no action of type "${action.type}" found.`
      );
  }
};

const ServiceCard = ({
  icp,
  isOpen,
  setElecUserType,
  setElecEmbeddedNetwork = undefined,
  setElecTrader = undefined,
  elecUserType,
  getIcpDetails,
  onReceiveIcpDetails,
  persistIcp,
  setModalOpened,
  getManualSearch,
  address,
  addValue,
  serviceType,
  list,
  setlist,
  classes = undefined,
  canOpen = false,
  isInList,
  setIsOpen,
  setNeedElecReconnection = void 0,
  embeddedNetwork = undefined,
  embeddedNetworkDesc = undefined,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Utils:
  const getMeterType = (code) => {
    if (!code) {
      return "-";
    }
    return code;
  };

  const setConnectionStatus = useCallback(
    (needReconnection) => {
      if (typeof setNeedElecReconnection === "function") {
        setNeedElecReconnection(needReconnection);
      }
    },
    [setNeedElecReconnection]
  );

  useEffect(() => {
    if (icp) {
      dispatch({ type: "selectICP", payload: icp });
    } else {
      dispatch({ type: "selectICP", payload: null });
    }
  }, [icp]);

  useEffect(() => {
    dispatch({ type: isOpen ? "openCard" : "closeCard" });
  }, [isOpen]);

  // API Calls
  // Fetch ICP Details
  useEffect(() => {
    if (state.icp) {
      getIcpDetails({ icp: state.icp }).then((resp) => {
        if (!isEmpty(resp.data.icp)) {
          setConnectionStatus(
            INACTIVE_CONNECTION_STATUSES.includes(resp.data.icp.status)
          );
          if (setElecEmbeddedNetwork) {
            setElecEmbeddedNetwork(resp.data.icp.embeddedNetwork);
          }
          if (setElecTrader) {
            setElecTrader(resp.data.icp.trader);
          }
          dispatch({
            type: "storeICPDetails",
            payload: resp.data.icp,
          });
          onReceiveIcpDetails(state.icp, resp.data.icp);
          persistIcp(state.icp);
        } else {
          dispatch({ type: "invalidICP" });
          setConnectionStatus(false);
        }
      });
    } else {
      dispatch({
        type: "storeICPDetails",
        payload: null,
      });
    }
  }, [
    getIcpDetails,
    onReceiveIcpDetails,
    persistIcp,
    setConnectionStatus,
    setElecEmbeddedNetwork,
    setElecTrader,
    setNeedElecReconnection,
    state.icp,
  ]);

  // Processors:
  // Meter Type & Switching Flag
  useEffect(() => {
    if (state.icpDetails && !isEmpty(state.icpDetails)) {
      dispatch({
        type: "setSwitching",
        payload: state.icpDetails?.switchInProgress === "true",
      });
      dispatch({
        type: "setMeterType",
        payload: getMeterType(state.icpDetails?.meterType),
      });
    } else {
      dispatch({ type: "setSwitching", payload: false });
      dispatch({ type: "setMeterType", payload: "-" });
    }
  }, [state.icpDetails]);

  const handleModalOpen = useCallback(
    (modalType) => () => {
      dispatch({ type: "openModal", payload: { modalType } });
      setModalOpened(modalType);
    },
    [dispatch, setModalOpened]
  );

  const handleManualAddressSubmission = useCallback(
    (address) => {
      getManualSearch(address).then((response) => {
        dispatch({ type: "searchResults", payload: response.data });
      });
    },
    [getManualSearch, dispatch]
  );

  // Helper for elec service card content
  const buildElectricityServiceContent = () => {
    return (
      <div className="serviceCard_content">
        <ServiceCardField
          fieldType="button"
          value={state.icp ?? "ICP not found"}
          title="Electricity ICP"
          buttonTitle="Copy"
          hasError={!state.icp}
        />
        <ServiceCardField
          fieldType="address"
          title="Registry Address"
          setShowAddressModal={handleModalOpen("AddressSearch")}
          setShowIcpModal={handleModalOpen("ICP")}
          value={
            state.icp && state.icpDetails?.fullAddress
              ? state.icpDetails.fullAddress
              : address
              ? address
              : ""
          }
          addressList={state.searchResults}
          onIcpSelect={(icp) => dispatch({ type: "selectICP", payload: icp })}
        />
        <ServiceCardField
          fieldType="standard"
          value={state.icpDetails?.status || "-"}
          title="Status"
          isValid
          showSuccessIcon={state.icpDetails?.status}
        />
        <ServiceCardField
          fieldType="dropdown"
          value={elecUserType}
          title="User type"
          dropdownItems={["Standard", "Low"]}
          style={{ margin: "10px 0 20px 0" }}
          setSelectedItem={setElecUserType}
          selectedItem={elecUserType}
        />
        <ServiceCardField
          fieldType="standard"
          value={state.isSwitching.toString()}
          title="Switch in progress"
          showCrossIcon={state.isSwitching}
        />
        <ServiceCardField
          fieldType="standard"
          value={state.icpDetails?.serial || "-"}
          title="Serial number"
        />
        <ServiceCardField
          fieldType="standard"
          value={state.meterType}
          title="Meter type"
        />
        <ServiceCardField
          fieldType="standard"
          value={state.icpDetails?.trader || "-"}
          title="Current retailer"
        />
        <ServiceCardField
          fieldType="standard"
          value={embeddedNetworkDesc || "-"}
          title="Embedded network"
          hasError={embeddedNetwork === "YesWithOtherRetailer"}
        />
      </div>
    );
  };

  const handleCheckboxClick = (e) => {
    if (e) {
      e.stopPropagation();
    }
    addValue(serviceType, list, setlist);
  };

  const onModalClose = () => {
    dispatch({ type: "closeModal" });
  };

  // Helper for elec service card
  const buildElectricityServiceCard = () => {
    return (
      <>
        <ExpandableCard
          headerContent={{
            cardTitle: "Electricity",
            cardIcon: <ArrowDown />,
            cardIsSelected: true,
            disabled: false,
            mainIcon: <Electricity />,
            withCheckbox: true,
            name: "electricityCheckbox",
          }}
          classes={classes}
          isOpen={state.isExpanded}
          name={"ELECTRICITY"}
          disabled={!canOpen}
          content={buildElectricityServiceContent()}
          onCheckboxChange={handleCheckboxClick}
          checked={isInList(serviceType, list)}
          handleClick={() => {
            dispatch({ type: state.isExpanded ? "closeCard" : "openCard" });
            setIsOpen(!state.isExpanded);
          }}
        />
        <ServiceModal
          modalType="address"
          isActive={state.showManualSearchForm}
          handlePrimaryClick={onModalClose}
          handleManualAddressSubmission={handleManualAddressSubmission}
          serviceType="electricity"
        />
        <ServiceModal
          modalType="icp"
          isActive={state.showManualICPForm}
          handlePrimaryClick={onModalClose}
          handleIcpChange={(icp) => {
            dispatch({ type: "selectICP", payload: icp });
          }}
          serviceType="electricity"
        />
      </>
    );
  };

  // Render card based on type
  switch (serviceType) {
    case "ELEC":
      return buildElectricityServiceCard();
    default:
      return null;
  }
};

export default ServiceCard;
